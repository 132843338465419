import { axiosNisystAdmin } from "@/services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ADD_EDIT_PERMISSION_PROFILE,
  DELETE_PERMISSION_PROFILE,
  GET_ALL_ASSIGNED_USERS,
  GET_ALL_MENU_LIST,
  GET_DEPARTMENT_INFORMATION,
  GET_PERMISSION_PROFILE_LIST_BY_ID,
  LIST_PERMISSION_PROFILE,
  UPSERT_ADMIN,
} from "@/services/url";
import { toast } from "react-toastify";

const initialState = {
  listPermissionProfile: [],
  permissionProfileDetail: null,
  masterData: null,
  userList: null,
  menuList: null,
  userFilters: {
    pageNumber: 1,
    pageSize: 10,
    search: "",
    totalRecords: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
  loading: false,
  searchLoading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    search: "",
    totalRecords: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    type: { label: "All", value: 0 },
    sorting: [],
  },
};

export const getAllMenuList = createAsyncThunk(
  `permissionProfile/getAllMenuList`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_ALL_MENU_LIST);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const upsertPermissionProfile = createAsyncThunk(
  `permissionProfile/upsertPermissionProfile`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        ADD_EDIT_PERMISSION_PROFILE,
        payload
      );
      if (response.data) {
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const fetchPermissionProfile = createAsyncThunk(
  `permissionProfile/fetchPermissionProfile`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        LIST_PERMISSION_PROFILE,
        payload
      );
      if (!!response?.data) {
        callback();
        return response.data?.data;
      }
    } catch (err) {
      if (err?.status !== 401 || err?.status !== 400) {
        callback();
      }
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getPermissionProfileListById = createAsyncThunk(
  `permissionProfile/getPermissionProfileListById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_PERMISSION_PROFILE_LIST_BY_ID,
        payload
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getMasterData = createAsyncThunk(
  `permissionProfile/getMasterData`,
  async (thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(GET_DEPARTMENT_INFORMATION);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const deletePermissionProfile = createAsyncThunk(
  `permissionProfile/deletePermissionProfile`,
  async (payload, thunkAPI) => {
    const { callback = () => {}, handleDeleteProfile = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_PERMISSION_PROFILE,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        handleDeleteProfile(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
      // toast.error(err?.response?.data?.message || err.message);
    }
  }
);

export const getAssignedUsers = createAsyncThunk(
  `permissionProfile/getAssignedUsers`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ALL_ASSIGNED_USERS,
        payload
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const addPermissionAdmin = createAsyncThunk(
  `permissionProfile/addPermissionAdmin`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(UPSERT_ADMIN, payload);
      if (response.data) {
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
const PermissionProfileSlice = createSlice({
  name: "permissionProfile",
  initialState: initialState,
  reducers: {
    setPermissionProfileLoading: (state, action) => {
      state.isListLoading = action?.payload;
    },
    setPermissionProfileFilters: (state, action) => {
      state.filters = { ...state.filters, ...action?.payload };
    },

    setPermissionProfileSortingFilters: (state, action) => {
      state.filters.sorting = action?.payload;
    },
    setPermissionListPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setUserSearchQuery: (state, action) => {
      state.userFilters.search = action.payload;
    },
    setUserPagination: (state, action) => {
      state.userFilters.pagination = action.payload;
    },
    setUserSorting: (state, action) => {
      state.userFilters.sorting = action.payload;
    },
    setUserLoading: (state, action) => {
      state.searchLoading = action?.payload;
    },
    setPermissionDetail: (state, action) => {
      state.permissionProfileDetail = action?.payload;
    },
    resetPermissionState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    // List PermissionProfile
    builder.addCase(fetchPermissionProfile.pending, (state) => {
      state.isListLoading = true;
    });
    builder.addCase(fetchPermissionProfile.fulfilled, (state, action) => {
      state.isListLoading = false;
      state.listPermissionProfile = action?.payload?.records;
      state.filters.totalRecords = action?.payload?.totalRecords;
    });
    builder.addCase(fetchPermissionProfile.rejected, (state) => {
      state.isListLoading = false;
      state.listPermissionProfile = [];
    });

    // Get Permission Profile List by Id
    builder.addCase(getPermissionProfileListById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPermissionProfileListById.fulfilled, (state, action) => {
      state.loading = false;
      state.permissionProfileDetail = action.payload?.data;
    });
    builder.addCase(getPermissionProfileListById.rejected, (state) => {
      state.loading = false;
      state.permissionProfileDetail = null;
    });

    // Get Master data for upsert admin
    builder.addCase(getMasterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMasterData.fulfilled, (state, action) => {
      state.loading = false;
      state.masterData = action.payload?.data;
    });
    builder.addCase(getMasterData.rejected, (state) => {
      state.loading = false;
      state.masterData = null;
    });

    // Get all assigned user
    builder.addCase(getAssignedUsers.pending, (state) => {
      state.searchLoading = true;
    });
    builder.addCase(getAssignedUsers.fulfilled, (state, action) => {
      state.searchLoading = false;
      state.userList = action.payload?.data?.records;
      state.userFilters.totalRecords = action?.payload?.data?.totalRecords;
    });
    builder.addCase(getAssignedUsers.rejected, (state) => {
      state.searchLoading = false;
      state.userList = null;
    });

    // Get all menu list
    builder.addCase(getAllMenuList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMenuList.fulfilled, (state, action) => {
      state.loading = false;
      state.menuList = action.payload?.data;
    });
    builder.addCase(getAllMenuList.rejected, (state) => {
      state.loading = false;
      state.menuList = null;
    });

    /* Reset entire state */
    builder.addCase(resetPermissionState, () => {
      return {
        ...initialState,
      };
    });
  },
});
export const {
  setPermissionProfileFilters,
  setPermissionListPagination,
  setPermissionProfileContactFilters,
  setPermissionProfileSortingFilters,
  setPermissionProfileLoading,
  setUserPagination,
  setUserSearchQuery,
  setUserSorting,
  setUserLoading,
  resetPermissionState,
  setPermissionDetail,
} = PermissionProfileSlice.actions;
export default PermissionProfileSlice.reducer;
