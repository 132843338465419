import { axiosNisystAdmin } from "@/services/api";
import {
  ACTIVE_SKILL,
  DELETE_SKILL,
  GET_ALL_SKILLS,
  GET_SKILL_BY_ID,
  UPSERT_SKILL,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const skillState = {
  skills: null,
  loading: false,
  error: null,
  totalCount: 0,
  skillsLoading: false,
  skillDetails: null,
  status: {
    label: "Active",
    value: 1,
  },
  filters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
};

export const getSkills = createAsyncThunk(
  `skillsThunk/getSkills`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Admin/" + GET_ALL_SKILLS,
        payload
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const deleteSkill = createAsyncThunk(
  `skillsThunk/deleteSkill`,
  async (payload, thunkAPI) => {
    const { callback = () => {}, handleDeleteSkill = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        `Admin/${DELETE_SKILL}`,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        handleDeleteSkill(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const activeDeActiveSkill = createAsyncThunk(
  `skillsThunk/activeDeActiveSkill`,
  async (payload, thunkAPI) => {
    const { callback } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        `Admin/${ACTIVE_SKILL}`,
        payload
      );
      if (response) {
        callback();
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getSkillById = createAsyncThunk(
  `skillsThunk/getSkillById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Admin/" + GET_SKILL_BY_ID,
        payload
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const upsertSkill = createAsyncThunk(
  `skillsThunk/upsertSkill`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Admin/" + UPSERT_SKILL,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const skillSlice = createSlice({
  name: "skill",
  initialState: skillState,
  reducers: {
    setSkillLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSkillSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setSkillPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setSkillSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setSkillStatus: (state, action) => {
      state.status = action.payload;
    },
    resetSkillState: () => {
      return {
        ...skillState,
      };
    },
  },

  extraReducers: (builder) => {
    //get All Skills
    builder.addCase(getSkills.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSkills.fulfilled, (state, action) => {
      state.loading = false;
      state.skills = action.payload?.data?.records;
      state.totalCount = action.payload?.data?.totalRecords;
    });
    builder.addCase(getSkills.rejected, (state) => {
      state.loading = false;
      state.skills = null;
    });

    //get Skill by Id
    builder.addCase(getSkillById.pending, (state) => {
      state.skillsLoading = true;
      state.skillDetails = null;
    });
    builder.addCase(getSkillById.fulfilled, (state, action) => {
      state.skillsLoading = false;
      state.skillDetails = action.payload?.data;
    });
    builder.addCase(getSkillById.rejected, (state) => {
      state.skillsLoading = false;
      state.skillDetails = null;
    });

    /* Reset entire state */
    builder.addCase(resetSkillState, () => {
      return {
        ...skillState,
      };
    });
  },
});

export default skillSlice.reducer;

export const {
  setSkillSearchQuery,
  setSkillPagination,
  setSkillSorting,
  setSkillStatus,
  setSkillLoading,
  resetSkillState,
} = skillSlice.actions;
