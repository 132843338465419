import { axiosNisystAdmin } from "@/services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GET_CONTACT_DETAILS_BY_ID,
  ADD_COMPANY,
  LIST_COMPANIES,
  ADD_COMPANY_CONTACT,
  LIST_COMPANIES_CONTACT,
  DELETE_COMPANY_CONTACT_DETAILS,
  GET_COMPANY_DETAILS_BY_ID,
  DELETE_COMPANY_DETAILS,
  SUSPEND_COMPANY_,
  UPLOAD_IMAGE,
  CHECK_ALL_COMPANY,
  GET_COMPANY_PRODUCTS
} from "@/services/url";
import { toast } from "react-toastify";

const initialState = {
  listCompanies: [],
  listCompaniesContact: [],
  companyDetails: {},
  contactDetails: {},
  productList: null,
  isContact: true,
  isEngineer: true,
  isProduct: true,
  loading: false,
  isListLoading: false,
  contactViewDetail: null,
  companyCodeList: null,
  companyProductList: null,
  companyProductLoading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    search: "",
    status: { label: "Active", value: 1 },
    totalRecords: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
  },
  
  companyProductFilters: {
    search: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalCount: 0,
    },
    sorting: [],
  },
  contactFilters: {
    pageNumber: 1,
    pageSize: 10,
    search: "",
    status: "",
    totalRecords: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
};

// Company List
export const fetchCompanies = createAsyncThunk(
  `companies/fetchCompanies`,
  async (payload, thunkAPI) => {
    const { callback } = payload;
    try {
      const response = await axiosNisystAdmin.post(LIST_COMPANIES, payload);
      if (!!response?.data) {
        callback();
        return response.data?.data;
      }
    } catch (err) {
      if (err?.status !== 401 || err?.status !== 400) {
        callback();
      }
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Company Product
export const getCompanyProductsList = createAsyncThunk(
  `companies/getCompanyProductsList`,
  async (payload, thunkAPI) => {
    const { callback } = payload;
    try {
      const response = await axiosNisystAdmin.post(GET_COMPANY_PRODUCTS, payload);
      if (!!response?.data) {
        callback();
        return response.data?.data;
      }
    } catch (err) {
      if (err?.status !== 401 || err?.status !== 400) {
        callback();
      }
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Upload Profile Image
export const UploadImage = createAsyncThunk(
  `companies/UploadImage`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPLOAD_IMAGE + "?Type=2",
        payload
      );
      if (!!response?.data) {
        return response.data?.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Get company detail by Id
export const viewCompanyDetailsById = createAsyncThunk(
  `companies/viewCompanyDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_COMPANY_DETAILS_BY_ID,
        payload
      );
      if (!!response?.data) {
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Upsert Company
export const addCompanies = createAsyncThunk(
  `companies/addCompanies`,
  async (payload, navigate, thunkAPI) => {
    try {
      const { callback = () => {} } = payload;
      const response = await axiosNisystAdmin.post(ADD_COMPANY, payload);
      if (!!response?.data) {
        callback();
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Company Contact List
export const fetchCompaniesContact = createAsyncThunk(
  `companies/fetchCompaniesContact`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        LIST_COMPANIES_CONTACT,
        payload
      );
      if (!!response?.data) {
        callback();
        return response.data?.data;
      }
    } catch (err) {
      callback();
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Get company contact detail by Id
export const getCompanyContactDetailById = createAsyncThunk(
  `companies/getCompanyContactDetailById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_CONTACT_DETAILS_BY_ID,
        payload
      );
      if (!!response?.data) {
        return response.data?.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Upsert Contact
export const addCompaniesContact = createAsyncThunk(
  `companies/addCompaniesContact`,
  async (payload, navigate, thunkAPI) => {
    try {
      const { callback = () => {} } = payload;
      const response = await axiosNisystAdmin.post(
        ADD_COMPANY_CONTACT,
        payload
      );
      if (!!response?.data) {
        callback();
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Suspend Company
export const suspendCompany = createAsyncThunk(
  `companies/suspendCompany`,
  async (payload, navigate, thunkAPI) => {
    try {
      const { callback = () => {} } = payload;
      const response = await axiosNisystAdmin.post(SUSPEND_COMPANY_, payload);
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Delete Company
export const deleteCompany = createAsyncThunk(
  `companies/deleteCompany`,
  async (payload, navigate, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_COMPANY_DETAILS,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Delete Company Contact
export const deleteCompanyContactDetails = createAsyncThunk(
  `companies/deleteCompanyContactDetails`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_COMPANY_CONTACT_DETAILS,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Check Company Code
export const checkCompanyCode = createAsyncThunk(
  `companies/checkCompanyCode`,
  async (payload, thunkAPI) => {
    const { callback } = payload;
    try {
      const response = await axiosNisystAdmin.post(CHECK_ALL_COMPANY, payload);
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const CompaniesSlice = createSlice({
  name: "companies",
  initialState: initialState,
  reducers: {
    // Company
    setCompaniesLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setCompaniesFilters: (state, action) => {
      state.filters = { ...state.filters, ...action?.payload };
    },
    setCompaniesContactFilters: (state, action) => {
      state.contactFilters = { ...state.contactFilters, ...action?.payload };
    },
    setCompaniesSortingFilters: (state, action) => {
      state.filters.sorting = action?.payload;
    },
    // Contact
    setCompaniesContactsSortingFilters: (state, action) => {
      state.contactFilters.sorting = action?.payload;
    },
    //
    setIsContact: (state, action) => {
      state.isContact = action?.payload;
    },
    setIsEngineer: (state, action) => {
      state.isEngineer = action?.payload;
    },
    setIsProduct: (state, action) => {
      state.isProduct = action?.payload;
    },
    setContactViewDetail: (state, action) => {
      state.contactViewDetail = action?.payload;
    },
    // Company Products
    setCompanyProductsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCompanyProductsSearchQuery: (state, action) => {
      state.companyProductFilters.searchQuery = action.payload;
    },
    setCompanyProductsPagination: (state, action) => {
      state.companyProductFilters.pagination = action.payload;
    },
    setCompanyProductsSorting: (state, action) => {
      state.companyProductFilters.sorting = action.payload;
    },
    setCompanyProductsFilters: (state, action) => {
      state.companyProductFilters = {...state.companyProductFilters , ...action.payload};
    },
    resetCompanyState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    // List Companies
    builder.addCase(fetchCompanies.pending, (state) => {
      state.isListLoading = true;
    });
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.isListLoading = false;
      state.listCompanies = action?.payload?.records;
      state.filters.totalRecords = action?.payload?.totalRecords;
    });
    builder.addCase(fetchCompanies.rejected, (state) => {
      state.isListLoading = false;
      state.listCompanies = [];
    });
    // Company Product List
    builder.addCase(getCompanyProductsList.pending, (state) => {
      state.companyProductLoading = true;
    });
    builder.addCase(getCompanyProductsList.fulfilled, (state, action) => {
      state.companyProductLoading = false;
      state.companyProductList = action?.payload?.records;
      state.companyProductFilters.totalCount = action?.payload?.totalRecords;
    });
    builder.addCase(getCompanyProductsList.rejected, (state) => {
      state.companyProductLoading = false;
      state.companyProductList = [];
    });
    // Upsert companies
    builder.addCase(addCompanies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCompanies.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addCompanies.rejected, (state) => {
      state.loading = false;
    });
    // Upsert Company Contact
    builder.addCase(addCompaniesContact.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCompaniesContact.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addCompaniesContact.rejected, (state) => {
      state.loading = false;
    });
    // List Company Contact
    builder.addCase(fetchCompaniesContact.pending, () => {});
    builder.addCase(fetchCompaniesContact.fulfilled, (state, action) => {
      state.listCompaniesContact = action?.payload?.records;
      state.contactFilters.totalRecords = action?.payload?.totalRecords;
    });
    builder.addCase(fetchCompaniesContact.rejected, (state) => {
      state.listCompaniesContact = [];
    });
    // Company Details by Id
    builder.addCase(viewCompanyDetailsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewCompanyDetailsById.fulfilled, (state, action) => {
      state.loading = false;
      state.companyDetails = action?.payload?.data;
    });
    builder.addCase(viewCompanyDetailsById.rejected, (state) => {
      state.loading = false;
      state.companyDetails = {};
    });
    // Company Contact Details by Id
    builder.addCase(getCompanyContactDetailById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanyContactDetailById.fulfilled, (state, action) => {
      state.loading = false;
      state.contactDetails = action?.payload;
    });
    builder.addCase(getCompanyContactDetailById.rejected, (state) => {
      state.loading = false;
      state.companyDetails = {};
    });
    // Check company code
    builder.addCase(checkCompanyCode.pending, (state) => {
      state.companyCodeList = [];
    });
    builder.addCase(checkCompanyCode.fulfilled, (state,action) => {
      state.companyCodeList = action?.payload;
    });
    builder.addCase(checkCompanyCode.rejected, (state) => {
      state.companyCodeList = [];
    });

    /* Reset entire state */
    builder.addCase(resetCompanyState, () => {
      return {
        ...initialState,
      };
    });
  },
});
export const {
  setCompaniesFilters,
  setCompaniesContactFilters,
  setCompaniesSortingFilters,
  setIsContact,
  setIsEngineer,
  setIsProduct,
  setContactViewDetail,
  setCompaniesContactsSortingFilters,
  setCompaniesLoading,
  resetCompanyState,
  setCompanyProductsLoading,
  setCompanyProductsSearchQuery,
  setCompanyProductsPagination,
  setCompanyProductsSorting,
  setCompanyProductsFilters,
} = CompaniesSlice.actions;
export default CompaniesSlice.reducer;
