/* eslint-disable no-undef */
import { axiosNisystAdmin } from "@/services/api";
import {
  CHECK_PRODUCT_SERIAL_NUMBER_EXISTS,
  DELETE_GOOD_RECEIPT_ITEM_ID,
  DELETE_RECEIPT,
  GET_GOOD_RECEIPT_ITEMS_LIST,
  GET_GOOD_RECEIPT_LIST,
  GET_MASTER_DATA_GOOD_RECEIPT,
  UPSERT_GOODSRECEIPT,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const goodReceiptState = {
  masterData: null,
  masterLoad: false,
  loading: true,
  listGoodReceipt: [],
  listGoodReceiptItems: [],
  recieptData: [],
  singleReciept: null,
  filters: {
    searchQuery: "",
    status: "Active",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalRecords: 0,
    },
    listPagination: {
      pageIndex: 0,
      pageSize: 10,
      totalRecords: 0,
    },
    sorting: [],
  },
  upsertGoodReceiptState: {
    id: "",
    message: "",
    loading: false,
  },
};
export const getGoodReceiptMasterData = createAsyncThunk(
  `getGoodReceiptMasterData`,
  async () => {
    try {
      const response = await axiosNisystAdmin.get(GET_MASTER_DATA_GOOD_RECEIPT);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const fetchListGoodReceipt = createAsyncThunk(
  `fetchListGoodReceipt`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_GOOD_RECEIPT_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const fetchListGoodReceiptItems = createAsyncThunk(
  `fetchListGoodReceiptItems`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_GOOD_RECEIPT_ITEMS_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const deleteGoodReceiptItem = createAsyncThunk(
  `deleteGoodReceiptItem`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_GOOD_RECEIPT_ITEM_ID,
        payload
      );
      callback(response?.data);
      return response.data;
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// export const blockGoodReceipt = createAsyncThunk(
//   `blockAdmin`,
//   async (payload, thunkAPI) => {
//     try {
//       console.log("payload ", payload);
//       const response = await axiosNisystAdmin.post(BLOCK_ADMIN, payload);
//       return response.data;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
//     }
//   }
// );

export const upsertGoodReceipt = createAsyncThunk(
  `upsertGoodReceipt`,
  async (payload, thunkAPI) => {
    try {
      // const { callback } = payload
      const response = await axiosNisystAdmin.post(
        UPSERT_GOODSRECEIPT,
        payload
      );
      // callback()
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const checkProductSerialNumberExists = createAsyncThunk(
  `checkProductSerialNumberExists`,
  async (payload, thunkAPI) => {
    try {
      // const { callback } = payload
      const response = await axiosNisystAdmin.post(
        CHECK_PRODUCT_SERIAL_NUMBER_EXISTS,
        payload
      );
      // callback()
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const deleteReceipt = createAsyncThunk(
  `goodReceipt/deleteReceipt`,
  async (payload, thunkAPI) => {
    const { callback = () => {}, handleDeleteReceipt = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        `${DELETE_RECEIPT}`,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        handleDeleteReceipt(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const GoodReceiptSlice = createSlice({
  name: "goodReceipt",
  initialState: goodReceiptState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUsersSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setUsersPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setListPagination: (state, action) => {
      state.filters.listPagination = action.payload;
    },
    setUsersSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setGoodReceiptItems: (state, action) => {
      state.recieptData = action.payload;
    },
    setSingleRecieptadata: (state, action) => {
      state.singleReciept = action.payload;
    },

    resetGoodReceipt: (state) => {
      state.upsertGoodReceiptState.id = "";
      state.upsertGoodReceiptState.message = "";
      state.upsertGoodReceiptState.loading = false;
      (state.listGoodReceiptItems = []),
        (state.filters = {
          searchQuery: "",
          status: "Active",
          pagination: {
            pageIndex: 0,
            pageSize: 10,
            totalRecords: 0,
          },
          sorting: [],
        });
    },

    resetGoodReceiptState: () => {
      return {
        ...goodReceiptState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListGoodReceipt.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchListGoodReceipt.fulfilled, (state, action) => {
        state.loading = false;
        state.listGoodReceipt = action.payload?.data?.records || [];
        state.totalRecords = action.payload?.data?.totalRecords || 0;
      })
      .addCase(fetchListGoodReceipt.rejected, (state) => {
        state.loading = false;
        state.listGoodReceipt = [];
      })
      .addCase(upsertGoodReceipt.pending, (state) => {
        state.upsertGoodReceiptState.loading = true;
      })
      .addCase(upsertGoodReceipt.fulfilled, (state, action) => {
        state.upsertGoodReceiptState.loading = false;
        state.upsertGoodReceiptState.id =
          action.payload?.data?.goodReceiptId || "";
        state.upsertGoodReceiptState.message = action.payload?.message || "";
        // Optionally, update state to reflect the upserted good receipt
      })
      .addCase(upsertGoodReceipt.rejected, (state, action) => {
        state.upsertGoodReceiptState.loading = false;
        state.upsertGoodReceiptState.message = action.payload?.message || "";
      })
      .addCase(fetchListGoodReceiptItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchListGoodReceiptItems.fulfilled, (state, action) => {
        state.loading = false;
        state.listGoodReceiptItems = action.payload?.data?.records || [];
        state.totalRecords = action.payload?.data?.totalRecords || 0;
      })
      .addCase(fetchListGoodReceiptItems.rejected, (state) => {
        state.loading = false;
        state.listGoodReceipt = [];
      })
      //getting master data
      .addCase(getGoodReceiptMasterData.pending, (state) => {
        state.masterLoad = true;
      })
      .addCase(getGoodReceiptMasterData.fulfilled, (state, action) => {
   
        state.masterLoad = false;
        state.masterData = action.payload?.data || [];
      })
      .addCase(getGoodReceiptMasterData.rejected, (state) => {
        state.masterLoad = false;
      });

    /* Reset entire state */
    builder.addCase(resetGoodReceiptState, () => {
      return {
        ...goodReceiptState,
      };
    });
  },
});

export const {
  setLoading,
  setUsersSearchQuery,
  setUsersPagination,
  setUsersSorting,
  resetGoodReceipt,
  setGoodReceiptItems,
  setSingleRecieptadata,
  resetGoodReceiptState,
  setListPagination,
} = GoodReceiptSlice.actions;

export default GoodReceiptSlice.reducer;
