import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAdminPermissionListById } from "@/modules/profile/slice/ProfileSlice";

const usePermissions = () => {
  const dispatch = useDispatch();
  const { profileDetail } = useSelector((state) => state?.profile);

  const fetchPermissions = useCallback(() => {
    if (profileDetail?.permissions) {
      dispatch(
        getAdminPermissionListById({
          permissionProfileId: profileDetail.permissions,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetail?.permissions]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);
  // Return fetchPermissions so it can be used externally
  return { fetchPermissions };
};

export default usePermissions;
