import { Suspense, useEffect, useState } from "react";
import Layout from "@/layout";
import PropTypes from "prop-types";
import routesConfig from "./routes.config";
import Loader from "@/components/common/loaders/Loader";
import {
  Route,
  Routes as ReactRouterDomRoutes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import _404 from "@/components/common/_404";
import routesConstants from "./routesConstants";
import { navLinks } from "@/constants/ConstantConfig";
import { CheckPermission } from "@/components/common/commonFunctions";
import AppLoader from "@/components/common/loaders/AppLoader";
import { useDispatch } from "react-redux";
import { fetchProfileDetail } from "@/modules/profile/slice/ProfileSlice";

const Common = (route) => (
  <Suspense fallback={<Loader />}>
    <route.component />
  </Suspense>
);

Common.prototype = {
  component: PropTypes.elementType.isRequired,
};

const Public = (route) => {
  // Logic for public routes
  // const { user } = useAuth();
  // const redirectTo = "/";
  // if (!!user) return <Navigate to={redirectTo} replace />;
  return (
    <Suspense fallback={<Loader />}>
      <route.component />
    </Suspense>
  );
};

Public.prototype = {
  ...Common.prototype,
};

const Private = (route) => {
  // Logic for Private routes
  const { component: Component, menuId } = route;
  const { profileDetail, adminPermissions } = useSelector(
    (state) => state.profile
  );
  const permission = CheckPermission(
    menuId,
    "all",
    adminPermissions?.permissionProfileMenuList
  );
  //   const currentUserRole = user.role;
  //   if (!!permissions?.length && !permissions.includes(currentUserRole))
  //     return <Navigate to={"/unauthorized"} replace />;
  if (!profileDetail) {
    return null;
  } else {
    if (
      (route?.roles?.includes(profileDetail?.userType) || !route?.roles) &&
      permission?.view
    ) {
      // If route path contains "edit", require edit permission
      if (
        (route.path?.includes("mode=edit") && !permission?.edit) ||
        (route.path?.includes("create") &&
          (!permission?.view || !permission?.add)) ||
        (route.path === ":id" && !permission?.view)
      ) {
        return <Navigate to={"/"} replace />;
      }
      return (
        <Suspense fallback={<Loader />}>
          <Component />
        </Suspense>
      );
    } else {
      return <Navigate to={"/"} />;
    }
  }
};

Private.prototype = {
  ...Common.prototype,
};

const createNestedRoutes = (routes, RouteType) => {
  return routes.map((route, i) => {
    if (!route.component) {
      throw new Error("Component must be required....");
    }
    if (route.children) {
      return (
        <Route path={route.path} key={i} element={<RouteType {...route} />}>
          {createNestedRoutes(route.children, RouteType)}
        </Route>
      );
    } else {
      return (
        <Route
          key={i}
          index={route.index}
          path={route.path}
          element={<RouteType {...route} />}
        />
      );
    }
  });
};

const Routes = () => {
  const { isAuth } = useSelector((state) => state.login);
  const { adminPermissions } = useSelector((state) => state.profile);
  const { common, private: privateRoutes, public: publicRoutes } = routesConfig;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // Find the first nav link with the "view" permission
  const firstLinkWithPermission = navLinks?.find((link) =>
    CheckPermission(
      link?.menuId,
      "view",
      adminPermissions?.permissionProfileMenuList
    )
  );

  useEffect(() => {
    if (isAuth) {
      setIsLoading(true);
      dispatch(fetchProfileDetail()).then(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return isLoading && isAuth ? (
    <AppLoader />
  ) : (
    <ReactRouterDomRoutes>
      {isAuth ? (
        <>
          <Route
            index
            path="/"
            element={<Navigate to={firstLinkWithPermission?.href} />}
          />
          <Route path="/" element={<Layout />}>
            {createNestedRoutes(privateRoutes, Private)}
          </Route>
          <Route path="*" element={<_404 />} />
        </>
      ) : (
        <>
          <Route
            index
            path="/"
            element={<Navigate to={routesConstants.LOGIN} />}
          />
          {createNestedRoutes(publicRoutes, Public)}
          <Route path="*" element={<Navigate to={routesConstants.LOGIN} />} />
        </>
      )}
      {createNestedRoutes(common, Common)}
    </ReactRouterDomRoutes>
  );
};

export default Routes;
