import { axiosNisystAdmin } from "@/services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CHECK_ALL_DELIVERY_NOTES,
  DELETE_DELIVERY_NOTES,
  GET_DELIVERY_NOTES_BY_ID,
  GET_DELIVERY_NOTES_LIST,
  UPSERT_DELIVERY_NOTES,
  GET_MASTER_DATA_DELIVERY_NOTES,
  GET_DELIVERY_NOTES_ITEMS_LIST,
  UPSERT_DELIVERY_NOTES_ITEMS,
  GET_ADDITIONAL_NOTES_LIST,
  UPSERT_DELIVERY_ADDITIONAL_NOTES,
  DELETE_DELIVERY_ADDITIONAL_NOTES,
  UPSERT_DELIVERY_NOTE_SERIAL_NUMBER,
  GET_SEARCH_DELIVERY_NOTES_LIST,
  DELETE_DELIVERY_NOTES_ITEMS,
  GENERATE_DELIVERY_NOTES,
  COMPLETE_DELIVERY_NOTES,
} from "@/services/url";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  deliveryNotesList: [],
  deliveryNotesItemList: null,
  additionalNoteList: null,
  searchDeliveryNotesList:null,
  productCodeList: [],
  deliveryNotesDetail: {},
  masterDataList: [],
  deliveryNotesItemsLoading: false,
  additionalNoteLoading: false,
  deliveryNotesItemsFilters: {
    search: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalCount: 0,
    },
    sorting: [],
  },
  additionalNoteFilters: {
    search: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalCount: 0,
    },
    sorting: [],
  },
  filters: {
    search: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalCount: 0,
    },
    sorting: [],
    status: { label: "NEW", value: 1 },
    date:{startDate:"", endDate:""}
  },
  searchFilters: {
    search: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalCount: 0,
    },
    sorting: [],
  },
 isOpenTable:{deliveryNotesItems:true, additionalNotes:true},
 printLoading:false,
 exportLoading:false,
};

//get delivery notes list
export const getDeliveryNotesList = createAsyncThunk(
  `deliveryNotes/getDeliveryNotesList`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        GET_DELIVERY_NOTES_LIST,
        payload
      );
      if (!!response?.data) {
        callback(payload?.isExport ? response?.data:"");
        return response.data;
      }
    } catch (err) {
      callback();
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);


//search delivery notes list
export const searchDeliverNotesList = createAsyncThunk(
  `deliveryNotes/searchDeliverNotesList`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        GET_SEARCH_DELIVERY_NOTES_LIST,
        payload
      );
      if (!!response?.data) {
        callback();
        return response.data;
      }
    } catch (err) {
      callback();
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// get delivery notes list items
export const getDeliveryNotesListItems = createAsyncThunk(
  `deliveryNotes/getDeliveryNotesListItems`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        GET_DELIVERY_NOTES_ITEMS_LIST,
        payload
      );
      if (!!response?.data) {
        callback();
        return response.data?.data;
      }
    } catch (err) {
      if (err?.status !== 401 || err?.status !== 400) {
        callback();
      }
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// get additional notes list
export const getAdditionalNotesList = createAsyncThunk(
  `deliveryNotes/getAdditionalNotesList`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        GET_ADDITIONAL_NOTES_LIST,
        payload
      );
      if (!!response?.data) {
        callback();
        return response.data?.data;
      }
    } catch (err) {
      if (err?.status !== 401 || err?.status !== 400) {
        callback();
      }
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
//Upsert delivery notes
export const upsertDeliveryNotes = createAsyncThunk(
  `deliveryNotes/upsertDeliveryNotes`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_DELIVERY_NOTES,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      callback("",true);
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// GET Master data
export const getAllMasterDataOfDeliveryNotes = createAsyncThunk(
  `deliveryNotes/getAllMasterDataOfDeliveryNotes`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(
        GET_MASTER_DATA_DELIVERY_NOTES
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Upsert Delivery notes items
export const upsertDeliveryNoteItemDetails = createAsyncThunk(
  `stockMovement/upsertDeliveryNoteItemDetails`,
  async (payload, thunkAPI) => {
    const { callback=()=>{} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_DELIVERY_NOTES_ITEMS,
        payload
      );
      if (!!response?.data) {
        callback(true);
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      callback(false)
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Upsert Delivery additonal notes
export const upsertDeliveryAdditionalNotes = createAsyncThunk(
  `stockMovement/upsertDeliveryAdditionalNotes`,
  async (payload, thunkAPI) => {
    const { callback=()=>{} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_DELIVERY_ADDITIONAL_NOTES,
        payload
      );
      if (!!response?.data) {
        callback();
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      callback();
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
export const getDeliveryNotesById = createAsyncThunk(
  `deliveryNotes/getDeliveryNotesById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_DELIVERY_NOTES_BY_ID,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// upsert serial number data 
export const UpsertDeliveryNoteDetailSerialNumber = createAsyncThunk(
  `deliveryNotes/UpsertDeliveryNoteDetailSerialNumber`,
  async (payload, thunkAPI) => {
    try {
const {callback = () =>{}}= payload
      const response = await axiosNisystAdmin.post(UPSERT_DELIVERY_NOTE_SERIAL_NUMBER, payload);
      if(!!response?.data){
        toast.success(response?.data?.message)
        callback()
        return response.data?.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//delete delivery notes
export const deleteDeliveryNotes = createAsyncThunk(
  `deliveryNotes/deleteDeliveryNotes`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_DELIVERY_NOTES,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//delete delivery additional notes
export const deleteDeliveryAdditionalNotes = createAsyncThunk(
  `deliveryNotes/deleteDeliveryAdditionalNotes`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_DELIVERY_ADDITIONAL_NOTES,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
    
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Check delivery serial number exists
export const checkCodeDeliveryNotes = createAsyncThunk(
  `deliveryNotes/checkCodeDeliveryNotes`,
  async (payload, thunkAPI) => {
    const {callback=()=>{}} = payload
    try {
      const response = await axiosNisystAdmin.post(CHECK_ALL_DELIVERY_NOTES, payload);
      if(!!response?.data){
        callback(response?.data)
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data)
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Delete delivery notes items



//delete delivery additional notes
export const deleteDeliveryNotesItems = createAsyncThunk(
  `deliveryNotes/deleteDeliveryNotesItems`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_DELIVERY_NOTES_ITEMS,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
    
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//print delivery notes
export const printDeliverNotes = createAsyncThunk(
  `deliveryNotes/printDeliverNotes`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        GENERATE_DELIVERY_NOTES,
        payload
      );
      if (!!response?.data) {
        callback(response?.data, true);
    
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data?.message, false);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
//print delivery notes
export const completeDeliveryNotes = createAsyncThunk(
  `deliveryNotes/completeDeliveryNotes`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        COMPLETE_DELIVERY_NOTES,
        payload
      );
      if (!!response?.data) {
        callback(response?.data, true);
    
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data?.message, false);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Export Delivery notes
export const exportDeliveryNotes = createAsyncThunk(
  `deliveryNotes/exportDeliveryNotes`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        GET_DELIVERY_NOTES_LIST,
        payload
      );
      if (!!response?.data) {
        callback(payload?.isExport ? response?.data:"");
        return response.data;
      }
    } catch (err) {
      callback();
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const deliveryNotes = createSlice({
  name: "deliveryNotes",
  initialState: initialState,
  reducers: {
    setDeliveryNotesLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsOpenTable: (state, action) => {
      state.isOpenTable = {...state.isOpenTable , ...action.payload};
    },
    setDeliveryNotesSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    setDeliveryNotesPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setDeliveryNotesSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setDeliveryNotesFilters: (state, action) => {
      state.filters.status = action.payload;
    },
    setDeliveryNotesDateFilters: (state, action) => {
      state.filters.date = action.payload;
    },
    //  search delivery notes list
    setSearchDeliveryNotesSearch: (state, action) => {
      state.searchFilters.search = action.payload;
    },
    setSearchDeliveryNotesPagination: (state, action) => {
      state.searchFilters.pagination = action.payload;
    },
    setSearchDeliveryNotesSorting: (state, action) => {
      state.searchFilters.sorting = action.payload;
    },
    setSearchDeliveryNotesFilters: (state, action) => {
      state.searchFilters = action.payload;
    },
    //  deliveryNotes item 
    setDeliveryNotesItemsLoading: (state, action) => {
      state.deliveryNotesItemsLoading = action.payload;
    },
    setDeliveryNotesItemsSearch: (state, action) => {
      state.deliveryNotesItemsFilters.search = action.payload;
    },
    setDeliveryNotesItemsPagination: (state, action) => {
      state.deliveryNotesItemsFilters.pagination = action.payload;
    },
    setDeliveryNotesItemsSorting: (state, action) => {
      state.deliveryNotesItemsFilters.sorting = action.payload;
    },
    setDeliveryNotesItemsFilters: (state, action) => {
      state.deliveryNotesItemsFilters = {
        ...state.deliveryNotesItemsFilters,
        ...action.payload,
      };
    },

    // additional notes
    setAdditionalNotesLoading: (state, action) => {
        state.additionalNoteLoading = action.payload;
      },
      setAdditionalNotesSearch: (state, action) => {
        state.additionalNoteFilters.search = action.payload;
      },
      setAdditionalNotesPagination: (state, action) => {
        state.additionalNoteFilters.pagination = action.payload;
      },
      setAdditionalNotesSorting: (state, action) => {
        state.additionalNoteFilters.sorting = action.payload;
      },
      setAdditionalNotesFilters: (state, action) => {
        state.additionalNoteFilters.status = action.payload;
      },
      setDeliveryNoteStatus: (state) => {
        state.deliveryNotesDetail = {}
      },
    resetDeliveryNoteState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    //get delivery notes list
    builder.addCase(getDeliveryNotesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDeliveryNotesList.fulfilled, (state, action) => {
      state.loading = false;
      state.deliveryNotesList = action.payload?.data?.records;
      state.filters.totalCount = action.payload?.data?.totalRecords;
    });
    builder.addCase(getDeliveryNotesList.rejected, (state) => {
      state.loading = false;
      state.deliveryNotesList = [];
    });

    //search delivery notes list
    builder.addCase(searchDeliverNotesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchDeliverNotesList.fulfilled, (state, action) => {
      state.loading = false;
      state.searchDeliveryNotesList = action.payload?.data?.records;
      state.searchFilters.totalCount = action.payload?.data?.totalRecords;
    });
    builder.addCase(searchDeliverNotesList.rejected, (state) => {
      state.loading = false;
      state.searchDeliveryNotesList = [];
    });
    //get delivery notes list items
    builder.addCase(getAdditionalNotesList.pending, (state) => {
      state.additionalNoteLoading = true;
    });
    builder.addCase(getAdditionalNotesList.fulfilled, (state, action) => {
      state.additionalNoteLoading = false;
      state.additionalNoteList = action?.payload?.records;
      state.additionalNoteFilters.totalCount = action?.payload?.totalRecords;
    });
    builder.addCase(getAdditionalNotesList.rejected, (state) => {
      state.additionalNoteLoading = false;
      state.additionalNoteList = [];
    });
    //get additional notes list
    builder.addCase(getDeliveryNotesListItems.pending, (state) => {
      state.deliveryNotesItemsLoading = true;
    });
    builder.addCase(getDeliveryNotesListItems.fulfilled, (state, action) => {
      state.deliveryNotesItemsLoading = false;
      state.deliveryNotesItemList = action?.payload?.records;
      state.deliveryNotesItemsFilters.totalCount =
        action?.payload?.totalRecords;
    });
    builder.addCase(getDeliveryNotesListItems.rejected, (state) => {
      state.deliveryNotesItemsLoading = false;
      state.deliveryNotesItemList = [];
    });
    // Product Details
    builder.addCase(getDeliveryNotesById.pending, (state) => {
      state.loading = true;
      state.deliveryNotesDetail = {};
    });
    builder.addCase(getDeliveryNotesById.fulfilled, (state, action) => {
      state.loading = false;
      state.deliveryNotesDetail = action.payload.data;
    });
    builder.addCase(getDeliveryNotesById.rejected, (state) => {
      state.loading = false;
      state.deliveryNotesDetail = {};
    });
    // Delete Product
    builder.addCase(deleteDeliveryNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDeliveryNotes.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDeliveryNotes.rejected, (state) => {
      state.loading = false;
    });

    // Upsert Product
    builder.addCase(upsertDeliveryNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertDeliveryNotes.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(upsertDeliveryNotes.rejected, (state) => {
      state.loading = false;
    });
    // Supplier List
    builder.addCase(getAllMasterDataOfDeliveryNotes.pending, (state) => {
      state.loading = true;
      state.masterDataList = [];
    });
    builder.addCase(getAllMasterDataOfDeliveryNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.masterDataList = action.payload?.data;
    });
    builder.addCase(getAllMasterDataOfDeliveryNotes.rejected, (state) => {
      state.loading = false;
      state.masterDataList = [];
    });
 // Print delivery notes
 builder.addCase(printDeliverNotes.pending, (state) => {
  state.printLoading = true;
});
builder.addCase(printDeliverNotes.fulfilled, (state) => {
  state.printLoading = false;
});
builder.addCase(printDeliverNotes.rejected, (state) => {
  state.printLoading = false;
});

 // Export delivery notes
 builder.addCase(exportDeliveryNotes.pending, (state) => {
  state.exportLoading = true;
});
builder.addCase(exportDeliveryNotes.fulfilled, (state) => {
  state.exportLoading = false;
});
builder.addCase(exportDeliveryNotes.rejected, (state) => {
  state.exportLoading = false;
});
    /* Reset entire state */
    builder.addCase(resetDeliveryNoteState, () => {
      return initialState;
    });
  },
});
export const {
  setDeliveryNotesSearch,
  setDeliveryNotesPagination,
  setDeliveryNotesSorting,
  setDeliveryNotesFilters,
  setSearchDeliveryNotesSearch,
  setSearchDeliveryNotesPagination,
  setSearchDeliveryNotesSorting,
  setSearchDeliveryNotesFilters,
  setDeliveryNotesLoading,
  resetDeliveryNoteState,
  setDeliveryNotesItemsLoading,
  setDeliveryNotesItemssearch,
  setDeliveryNotesItemsPagination,
  setDeliveryNotesItemsSorting,
  setDeliveryNotesItemsFilters,
  setAdditionalNotesSearch,
  setAdditionalNotesPagination,
  setAdditionalNotesSorting,
  setAdditionalNotesFilters,
  setAdditionalNotesLoading,
  setIsOpenTable,
  setDeliveryNotesDateFilters,
  setDeliveryNoteStatus
} = deliveryNotes.actions;
export default deliveryNotes.reducer;
