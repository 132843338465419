import { toast } from "react-toastify";
import { axiosNisystAdmin } from "@/services/api";
import {
  ARCHIVE_TEMPLATE_BY_ID,
  DELETE_TEMPLATE_BY_ID,
  GET_ALL_TEMPLATES,
  GET_TEMPLATE_BY_ID,
  UPSERT_TEMPLATE,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { somethingWentWrong } from "@/constants/SchemaValidation";

const templateState = {
  templatesList: [],
  templatesListData: {},
  loading: true,
  viewTemplateData: {},
  templateDetailLoader: false,
  filters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
    status: 1,
  },
};

//Get all templates list
export const getTemplates = createAsyncThunk(
  `TemplateThunk/getTemplates`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_ALL_TEMPLATES, payload);
      return response?.data;
    } catch (err) {
      const errorMessage = err?.response?.data?.message || err.message;
      toast.error(errorMessage || somethingWentWrong);
      return thunkAPI.rejectWithValue({
        statusCode: err?.response?.data?.statusCode,
        message: errorMessage,
      });
    }
  }
);

export const getViewTemplate = createAsyncThunk(
  `TemplateThunk/getViewTemplate`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(GET_TEMPLATE_BY_ID, payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  `TemplateThunk/deleteTemplate`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        payload?.isDelete ? DELETE_TEMPLATE_BY_ID : ARCHIVE_TEMPLATE_BY_ID,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

//Upsert Template
export const upsertTemplate = createAsyncThunk(
  `TemplateThunk/upsertTemplate`,
  async (payload, thunkAPI) => {
    try {
      const { callback } = payload;
      const response = await axiosNisystAdmin.post(UPSERT_TEMPLATE, payload);
      if (!!response?.data) {
        callback();
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || somethingWentWrong);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const templateSlice = createSlice({
  name: "templates",
  initialState: templateState,
  reducers: {
    setTemplateLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTemplateSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setTemplatePagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setTemplateSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setTemplateStatus: (state, action) => {
      state.filters.status = action.payload;
    },
    resetTemplateState: () => {
      return {
        ...templateState,
      };
    },
  },

  extraReducers: (builder) => {
    //get all templates list
    builder.addCase(getTemplates.pending, () => {
      // state.loading = true;
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.loading = false;
      state.templatesList = action.payload?.data?.records;
      state.templatesListData = action.payload.data;
    });
    builder.addCase(getTemplates.rejected, (state) => {
      state.loading = false;
      state.templatesList = [];
    });
    //get template by id
    builder.addCase(getViewTemplate.pending, (state) => {
      state.templateDetailLoader = true;
    });
    builder.addCase(getViewTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.viewTemplateData = action?.payload?.data?.data;
    });
    builder.addCase(getViewTemplate.rejected, (state) => {
      state.loading = false;
      state.viewTemplateData = {};
    });
    // Upsert Template
    builder.addCase(upsertTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertTemplate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(upsertTemplate.rejected, (state) => {
      state.loading = false;
    });
    /* Reset entire state */
    builder.addCase(resetTemplateState, () => {
      return {
        ...templateState,
      };
    });
  },
});

export const {
  setTemplateLoading,
  setTemplateSearchQuery,
  setTemplatePagination,
  setTemplateSorting,
  setTemplateStatus,
  resetTemplateState,
} = templateSlice.actions;
export default templateSlice.reducer;
