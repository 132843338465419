import ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "@/assets/scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/ErrorBoundry";

ReactDOMClient.createRoot(document.querySelector("#root")).render(
  <BrowserRouter>
    <ToastContainer />
    <ErrorBoundary>
        <App />
      </ErrorBoundary>
  </BrowserRouter>
);
