import { axiosNisystAdmin } from "@/services/api";
import {
  GET_TOP_ENGINEERS,
  GET_COMPLETE_JOB,
  GET_JOB_OVERVIEW_DATA,
  GET_PRODUCT_DETAILS_CARD,
  UPCOMING_JOBS,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const staticData = [
  {
    engineer_count: 150,
    companyId: "743c8fa1-5de3-4c43-9ddb-0650939aaa99",
    companyName: "Billionaire Girls Club",
  },
  {
    companyId: "2b904631-262c-4f79-8203-0d3be498631c",
    companyName: "Company",
    engineer_count: 200,
  },
  {
    companyId: "ec3da9c2-d423-4890-8aa5-12dc1a57c1d1",
    companyName: "ffadsfdsafas",
    engineer_count: 170,
  },
  {
    companyId: "4723f498-c66b-487a-8f7d-17e5bc74c8cf",
    companyName: "Soto and Wall Plc",
    engineer_count: 240,
  },
  {
    companyId: "60d9b778-e05c-4f23-94e8-303a0c80d32b",
    companyName: "Sonu Company",
    engineer_count: 300,
  },
  {
    companyId: "616703dd-0847-4b1e-a792-4e2844d68c80",
    companyName: "Yash test Company",
    engineer_count: 90,
  },
  {
    companyId: "c70ab7fe-a5e5-4d3c-9724-188bee8dad36",
    companyName: "Fischer Molina Trading",
    engineer_count: 60,
  },
  {
    companyId: "22aa2290-6a09-48f7-ba54-270fbb06cd8d",
    companyName: "Brown and King LLC",
    engineer_count: 230,
  },
  {
    companyId: "35441969-7caa-4ac6-beee-318dd6e2f9ea",
    companyName: "Fuller Leonard Inc",
    engineer_count: 180,
  },
  {
    companyId: "0b1e8564-6cc2-415e-89fc-33ed45c5f047",
    companyName: "Hudson Clayton Associates",
    engineer_count: 210,
  },
  {
    companyId: "f8150952-600b-47d9-b96d-36ed36ecab44",
    companyName: "Differenzsystem LLP",
    engineer_count: 180,
  },
  {
    companyId: "5e835fa9-8123-4d05-b956-4baac8bc978d",
    companyName: "Merritt Gallagher Plcc",
    engineer_count: 100,
  },
  {
    companyId: "e45842cd-1705-4042-96ca-4dee30cc16cc",
    companyName: "gfgsf",
    engineer_count: 180,
  },
  {
    companyId: "616703dd-0847-4b1e-a792-4e2844d68c80",
    companyName: "Yash test Company",
    engineer_count: 181,
  },
  {
    companyId: "d5728166-b6e6-4885-a849-5474b75af164",
    companyName: "Ross Barton Traders d",
    engineer_count: 182,
  },
  {
    companyId: "e3ec8c45-afcf-476b-9f97-5868aa68bfdf",
    companyName: "Tech Innovations Ltd",
    engineer_count: 183,
  },
  {
    companyId: "edbcdbbf-1549-4c8c-8555-5aaa029f55ed",
    companyName: "Knapp and Vance Plcfgfgsdfdfgssdfgsdfgsdfgsdf",
    engineer_count: 184,
  },
  {
    companyId: "bd7fd865-fed6-429c-a1c2-70826f2c8b4b",
    companyName: "Koch and Monroe Co",
    engineer_count: 185,
  },
  {
    companyId: "76ff6b99-3add-43fa-b343-7449d6c2bc7e",
    companyName: "West and Barry Traders",
    engineer_count: 186,
  },
  {
    companyId: "12efd42a-6a3e-4e1a-beec-74f1d99b839f",
    companyName: "Daniels",
    engineer_count: 187,
  },
  {
    companyId: "64893720-30d7-496b-a76b-76baa711fa8a",
    companyName: "Tyson Humphrey Trading",
    engineer_count: 188,
  },
  {
    companyId: "71915312-5e4a-428f-b4a9-76f6fb8eda06",
    companyName: "Last One",
    engineer_count: 189,
  },
  {
    companyId: "a125ac39-5121-4e05-b5ad-7b65223e6fb8",
    companyName: "Tech Innovations Ltd.",
    engineer_count: 190,
  },
  {
    companyId: "b56a45b8-f022-4a6f-a95a-7bc0bb4d241a",
    companyName: "Holcomb Miranda Traders",
    engineer_count: 191,
  },
  {
    companyId: "58d97757-361e-4e7c-8d9a-7d6400800d4a",
    companyName: "Yash test company 2",
    engineer_count: 192,
  },
  {
    companyId: "7c9456cc-7318-4d96-9dfd-8a0e85074c37",
    companyName: "Logic Techs",
    engineer_count: 193,
  },
  {
    companyId: "99365251-4ba3-4577-856b-8e0bb2800e1d",
    companyName: "diffferenz",
    engineer_count: 194,
  },
  {
    companyId: "35b80525-dfb6-49df-a190-93b374a988f8",
    companyName: "Rodriguez Jarvis Associates",
    engineer_count: 195,
  },
  {
    companyId: "c62d7071-3522-4691-b3e2-9c9c2e859308",
    companyName: "Gaines and Wheeler Co",
    engineer_count: 196,
  },
  {
    companyId: "31b16564-a934-465d-9a39-b5c3fd479a47",
    companyName: "Rivera Mcintyre Co",
    engineer_count: 197,
  },
  {
    companyId: "a19d9ede-25b1-4b02-b38e-bc1bf209f089",
    companyName: "Figueroa and Guerrero Co",
    engineer_count: 70,
  },
  {
    companyId: "4babed3f-9f00-47d5-a8d9-bc47dd1e97e1",
    companyName: "One8 Community",
    engineer_count: 198,
  },
  {
    companyId: "f2b869dd-72cd-4803-b143-c3af26e76b1a",
    companyName: "Whitfield and Marks Associates",
    engineer_count: 199,
  },
  {
    companyId: "07b5a7d3-5df0-4f3b-b109-c92ff9166317",
    companyName: "hgfh",
    engineer_count: 200,
  },
  {
    companyId: "9bda875f-e90d-47e9-9f4b-cc2d40b4fefd",
    companyName: "Nixon and Grant Plc",
    engineer_count: 201,
  },
  {
    companyId: "f874da7e-cb8a-4a2b-82f8-d0abd04d3fba",
    companyName: "Bennett Mendoza LLC",
    engineer_count: 202,
  },
  {
    companyId: "82d4e372-71b2-44b0-b9cd-d1de40445e77",
    companyName: "Madhav Company",
    engineer_count: 203,
  },
  {
    companyId: "4fecdac4-1782-45e0-85c3-da9751cabffa",
    companyName: "Tech Lab",
    engineer_count: 204,
  },
  {
    companyId: "843de073-7551-4351-98c5-e952451acf5b",
    companyName: "Jenil Test",
    engineer_count: 205,
  },
  {
    companyId: "47331cc0-e017-4019-a8da-efe64394fcae",
    companyName: "John Cenacompany",
    engineer_count: 206,
  },
  {
    companyId: "147b9d8c-97d2-4d84-8fb9-f22fb3bec5e1",
    companyName: "Unknown Company",
    engineer_count: 207,
  },
  {
    companyId: "d9dcf554-31d7-4a16-ae7e-f4b82e48943d",
    companyName: "Hale and Porter Traders",
    engineer_count: 208,
  },
  {
    companyId: "5f016f71-63c0-4e84-9d60-ff7befbbe194",
    companyName: "Knapp and Vance Plcfgfgsdfdfgssdfgsdfgsdfgsdf",
    engineer_count: 209,
  },
];

const dashboardState = {
  loading: false,
  companyByEngineer: {
    filter: null,
    data: staticData,
  },
  dashboardCards: null,
  upcomingJobs: null,
  upcomingLoading: null,
  topEngineers: null,
  completedJobs: null,
  jobOverviewData: null,
  jobCount: 0,
  jobFilters: {
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
};

export const getProductDetailCards = createAsyncThunk(
  `dashboardThunk/getProductDetailCards`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(GET_PRODUCT_DETAILS_CARD);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getUpcomingJobs = createAsyncThunk(
  `dashboardThunk/getUpcomingJobs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(UPCOMING_JOBS, payload);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// Top Engineers
export const GetTopEngineers = createAsyncThunk(
  `dashboardThunk/GetTopEngineers`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_TOP_ENGINEERS, {
        ...payload,
      });
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getCompletedJobs = createAsyncThunk(
  `dashboardThunk/getCompletedJobs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(GET_COMPLETE_JOB);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getJobOverviewData = createAsyncThunk(
  `dashboardThunk/getJobOverviewData`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_JOB_OVERVIEW_DATA,
        payload
      );
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCompanyByEngineerFilter: (state, action) => {
      state.companyByEngineer.filter = action.payload;
    },
    setJobOverviewPagination: (state, action) => {
      state.jobFilters.pagination = action.payload;
    },
    setJobOverviewSorting: (state, action) => {
      state.jobFilters.sorting = action.payload;
    },
    resetDashboardState: () => {
      return {
        ...dashboardState,
      };
    },
  },

  extraReducers: (builder) => {
    /*  Get Product Details Card */
    builder.addCase(getProductDetailCards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductDetailCards.fulfilled, (state, action) => {
      state.loading = false;
      state.dashboardCards = action.payload?.data?.data;
    });
    builder.addCase(getProductDetailCards.rejected, (state) => {
      state.loading = false;
      state.dashboardCards = null;
    });

    /*  Get Upcoming Jobs */
    builder.addCase(getUpcomingJobs.pending, (state) => {
      state.upcomingLoading = true;
    });
    builder.addCase(getUpcomingJobs.fulfilled, (state, action) => {
      state.upcomingLoading = false;
      state.upcomingJobs = action.payload?.data?.data;
    });
    builder.addCase(getUpcomingJobs.rejected, (state) => {
      state.upcomingLoading = false;
      state.upcomingJobs = null;
    });

    // Top engineers

    builder.addCase(GetTopEngineers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetTopEngineers.fulfilled, (state, action) => {
      state.loading = false;
      state.topEngineers = action.payload?.data?.data;
    });
    builder.addCase(GetTopEngineers.rejected, (state) => {
      state.loading = false;
      state.topEngineers = null;
    });

    /*  Get Completed Jobs */
    builder.addCase(getCompletedJobs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompletedJobs.fulfilled, (state, action) => {
      state.loading = false;
      state.completedJobs = action.payload?.data?.data;
    });
    builder.addCase(getCompletedJobs.rejected, (state) => {
      state.loading = false;
      state.completedJobs = null;
    });

    /*  Get Job Overview Data */
    builder.addCase(getJobOverviewData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobOverviewData.fulfilled, (state, action) => {
      state.loading = false;
      state.jobOverviewData = action.payload?.data?.data?.records;
      state.jobCount = action.payload?.data?.data?.totalRecords;
    });
    builder.addCase(getJobOverviewData.rejected, (state) => {
      state.loading = false;
      state.jobOverviewData = null;
    });

    /* Reset entire state */
    builder.addCase(resetDashboardState, () => {
      return {
        ...dashboardState,
      };
    });
  },
});

export const {
  setLoading,
  setCompanyByEngineerFilter,
  resetDashboardState,
  setJobOverviewPagination,
  setJobOverviewSorting,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
