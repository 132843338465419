import { axiosNisystAdmin } from "@/services/api";
import {
  APPROVE_ENGINEER,
  BLOCK_ENGINEER,
  DELETE_ENGINEER,
  GET_ALL_ENGINEERS,
  GET_ALL_SKILLS_LIST,
  GET_COMPANY_LIST,
  GET_ENGINEERS_BY_ID,
  JOBS_BY_ENGINEER_ID,
  UPSERT_ENGINEER,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const engineerState = {
  engineers: null,
  companyList: null,
  skillsList: null,
  engineerDetails: null,
  engineerLoading: false,
  loading: false,
  error: null,
  jobList: null,
  totalCount: 0,
  totalJobCount: 0,
  status: {
    label: "Active",
    value: 2,
  },
  filters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
    company: "",
  },
  jobFilters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
};

export const getEngineers = createAsyncThunk(
  `engineersThunk/getEngineers`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Admin/" + GET_ALL_ENGINEERS,
        payload
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const deleteEngineer = createAsyncThunk(
  `engineersThunk/deleteEngineer`,
  async (payload, thunkAPI) => {
    const { callback = () => {}, handleDeleteEngineer = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        `Admin/${DELETE_ENGINEER}`,
        payload
      );
      if (!!response?.data) {
        callback(response?.data);
        handleDeleteEngineer(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const blockEngineer = createAsyncThunk(
  `engineersThunk/blockEngineer`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        `Admin/${BLOCK_ENGINEER}`,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getAllCompanyList = createAsyncThunk(
  `engineersThunk/getAllCompanyList`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(
        "Company/" + GET_COMPANY_LIST
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getAllSkillsList = createAsyncThunk(
  `engineersThunk/getAllSkillsList`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(
        "Admin/" + GET_ALL_SKILLS_LIST
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getEngineerDetails = createAsyncThunk(
  `engineersThunk/getEngineerDetails`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Admin/" + GET_ENGINEERS_BY_ID,
        payload
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const approveEngineer = createAsyncThunk(
  `engineersThunk/approveEngineer`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        "Admin/" + APPROVE_ENGINEER,
        payload
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const fetchEngineerJobs = createAsyncThunk(
  `engineersThunk/fetchEngineerJobs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        JOBS_BY_ENGINEER_ID,
        payload
      );
      // toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const upsertEngineer = createAsyncThunk(
  `engineersThunk/upsertEngineer`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(UPSERT_ENGINEER, payload);
      toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const engineerSlice = createSlice({
  name: "engineer",
  initialState: engineerState,
  reducers: {
    setEngineerLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEngineerSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setEngineerPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setEngineerSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setEngineerStatus: (state, action) => {
      state.status = action.payload;
    },
    setCompanyFilter: (state, action) => {
      state.filters.company = action.payload;
    },
    setJobSearchQuery: (state, action) => {
      state.jobFilters.searchQuery = action.payload;
    },
    setJobPagination: (state, action) => {
      state.jobFilters.pagination = action.payload;
    },
    setJobSorting: (state, action) => {
      state.jobFilters.sorting = action.payload;
    },
    resetEngineerState: () => {
      return {
        ...engineerState,
      };
    },
  },

  extraReducers: (builder) => {
    //get All Skills
    builder.addCase(getEngineers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEngineers.fulfilled, (state, action) => {
      state.loading = false;
      state.engineers = action.payload?.data?.records;
      state.totalCount = action.payload?.data?.totalRecords;
    });
    builder.addCase(getEngineers.rejected, (state) => {
      state.loading = false;
      state.engineers = null;
    });

    /* All Company List */
    builder.addCase(getAllCompanyList.pending, (state) => {
      state.loading = true;
      state.companyList = null;
    });
    builder.addCase(getAllCompanyList.fulfilled, (state, action) => {
      state.loading = false;
      state.companyList = action.payload?.data;
    });
    builder.addCase(getAllCompanyList.rejected, (state) => {
      state.loading = false;
      state.companyList = null;
    });

    /* All Skills List */
    builder.addCase(getAllSkillsList.pending, (state) => {
      state.loading = true;
      state.skillsList = null;
    });
    builder.addCase(getAllSkillsList.fulfilled, (state, action) => {
      state.loading = false;
      state.skillsList = action.payload?.data;
    });
    builder.addCase(getAllSkillsList.rejected, (state) => {
      state.loading = false;
      state.skillsList = null;
    });

    /* Engineer Details by id */
    builder.addCase(getEngineerDetails.pending, (state) => {
      state.engineerLoading = true;
      state.engineerDetails = null;
    });
    builder.addCase(getEngineerDetails.fulfilled, (state, action) => {
      state.engineerLoading = false;
      state.engineerDetails = action.payload.data;
    });
    builder.addCase(getEngineerDetails.rejected, (state) => {
      state.engineerLoading = false;
      state.engineerDetails = null;
    });

    /* Job list by engineer id */
    builder.addCase(fetchEngineerJobs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEngineerJobs.fulfilled, (state, action) => {
      state.loading = false;
      state.jobList = action.payload.data?.records;
      state.totalJobCount = action.payload?.data?.totalRecords;
    });
    builder.addCase(fetchEngineerJobs.rejected, (state) => {
      state.loading = false;
      state.jobList = null;
    });

    /* Reset entire state */
    builder.addCase(resetEngineerState, () => {
      return {
        ...engineerState,
      };
    });
  },
});

export default engineerSlice.reducer;

export const {
  setEngineerLoading,
  setEngineerSearchQuery,
  setEngineerPagination,
  setEngineerSorting,
  setEngineerStatus,
  setCompanyFilter,
  resetEngineerState,
  setJobPagination,
  setJobSearchQuery,
  setJobSorting,
} = engineerSlice.actions;
