/* eslint-disable no-empty-pattern */
import { axiosNisystAdmin } from "@/services/api";
import { ADD_EDIT_SUPPLIER, DELETE_SUPPLIER_BY_ID, GET_SUPPLIER_BY_ID, LIST_SUPPLIERS } from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    supplierList: [],
    supplierData: {},
    supplierDetail: {},
    supplierDetailLoader: false,
    loading: true,
    filters: {
        sorting: [],
        pagination: {
            pageIndex: 0,
            pageSize: 10,
            totalRecords: 0,
        },
        search: "",
    }
};

export const fetchSupplier = createAsyncThunk(
    `suppliers/fetchSupplier`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(LIST_SUPPLIERS, payload);
            return response;
        } catch (err) {
            return err;
        }
    }
);

export const addEditSupplier = createAsyncThunk(
    `suppliers/addEditSupplier`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(ADD_EDIT_SUPPLIER, payload);
            return response;
        } catch (err) {
            return err;
        }
    }
);

export const getSupplierById = createAsyncThunk(
    `suppliers/getSupplierById`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(GET_SUPPLIER_BY_ID, payload);
            return response;
        } catch (err) {
            return err
        }
    }
);
export const deleteSupplierById = createAsyncThunk(
    `suppliers/deleteSupplierById`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(DELETE_SUPPLIER_BY_ID, payload);
            return response;
        } catch (err) {
            return err
        }
    }
);



const SupplierSlice = createSlice({
    name: "suppliers",
    initialState: initialState,
    reducers: {
        setSuppliersLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSuppliersSearchQuery: (state, action) => {
            state.filters.search = action.payload
        },
        setSuppliersSorting: (state, action) => {
            state.filters.sorting = action.payload;
        },
        setSuppliersPagination: (state, action) => {
            state.filters.pagination = action.payload;
        },
  resetSupplierState: () => {
      return initialState;
    },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSupplier.pending, () => {
        });
        builder.addCase(fetchSupplier.fulfilled, (state, action) => {
            state.supplierList = action.payload.data?.data?.records;
            state.supplierData = action.payload.data?.data
            state.loading = false
        });
        builder.addCase(fetchSupplier.rejected, (state) => {
            state.supplierList = [];
            state.loading = false
        });

        builder.addCase(getSupplierById.pending, (state) => {
            state.supplierDetailLoader = true
        });
        builder.addCase(getSupplierById.fulfilled, (state, action) => {
            state.supplierDetail = action.payload.data?.data
            state.supplierDetailLoader = false
        });
        builder.addCase(getSupplierById.rejected, (state) => {
            state.supplierDetailLoader = false
        });
 /* Reset entire state */
    builder.addCase(resetSupplierState, () => {
      return {
        ...initialState,
      };
    });

    },
});
export const {
  setSuppliersLoading,
  setSuppliersSearchQuery,
  setSuppliersSorting,
  setSuppliersPagination,
  resetSupplierState,
} = SupplierSlice.actions;
export default SupplierSlice.reducer;

