import { emojiRegex } from "@/constants/ConstantConfig";
import { forwardRef } from "react";
const CommonTextareaField = (
  {
    labelName,
    required,
    readOnly,
    mutedText,
    isInvalid = false,
    errorText,
    value = "",
    applyPaddingBottom = true,
    preRegistrationFlag = false,
    labelFlag = false,
    className,
    placeHolder,
    rows,
    isDisabled = false,
    ...inputProps
  },
  ref
) => {
  const handleChange = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(emojiRegex, "").replace(/"/g, "");
    event.target.value = sanitizedValue;
    inputProps.onChange && inputProps.onChange(event);
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const sanitizedValue = pastedData.replace(emojiRegex, "").replace(/"/g, "");
    // Update the input value with the truncated data
    document.execCommand("insertText", false, sanitizedValue);
    // Prevent the default paste behavior
    event.preventDefault();
  };

  return (
    <div
      className={`${
        (applyPaddingBottom || preRegistrationFlag) && !labelFlag ? "field" : ""
      } ${applyPaddingBottom || labelFlag ? "padding-field" : ""}`}
    >
      {labelName ? <label className={"label"}>{labelName}</label> : null}
      {required ? <span className="text-danger">*</span> : null}
      {mutedText ? <p className={"text-muted"}>{mutedText}</p> : null}
      <textarea
        className={`${"form-control-textarea"} ${
          isInvalid ? "invalid" : ""
        } ${className}`}
        rows={rows}
        readOnly={!!readOnly}
        value={value}
        placeholder={placeHolder}
        ref={ref}
        {...inputProps}
        onChange={handleChange}
        onPaste={handlePaste}
        disabled={isDisabled}
      />
      {isInvalid && errorText ? (
        <label htmlFor={inputProps.name} className={`${"error-text"} m-0`}>
          {errorText}
        </label>
      ) : null}
    </div>
  );
};
export default forwardRef(CommonTextareaField);
