import { useEffect } from "react";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import store from "./store";
import Routes from "./routes";
import PageLoader from "./components/common/loaders/PageLoader";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [location?.pathname]);

  return (
    <Provider store={store}>
      <Routes />
      <PageLoader />
    </Provider>
  );
};
export default App;
