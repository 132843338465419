import { footerLinks } from "@/constants/ConstantConfig";
import ContactUs from "@/modules/common/ContactUs";
import { useState } from "react";
import routesConstants from "@/routes/routesConstants";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <footer className="footer">
        <div className="footer-sec set_content justify-content-between w-100 px-5">
          <p>
            Copyright © 2024 Nirvana Intelligent Systems Ltd. All rights
            reserved.
          </p>
          <div className="copy_right_text">
            {footerLinks?.map((link, index) => (
              <span
                key={index}
                className="mx-3 c_pointer"
                onClick={() => {
                  if (link === "Contact Us") {
                    setIsOpen(true); // Open modal on "Contact Us" click
                  } else if (link === "About Us") {
                    navigate(routesConstants?.ABOUT_US);
                  } else if (link === "FAQs") {
                    navigate(routesConstants?.FAQ);
                  }
                }}
              >
                {link}
              </span>
            ))}
          </div>
        </div>
      </footer>
      <ContactUs isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
export default Footer;
