import React from "react";

const CommonButton = ({
  children,
  name,
  isPrimary,
  isWarning,
  isSecondary,
  hasBorder,
  isLarge,
  isBold = true,
  to,
  className,
  type = "button",
  applyMinWidth = true,
  isSmall,
  isMargin = false,
  onClick,
  ...args
}) => {
  return (
    <button
      type={type}
      style={{ margin: isMargin ? "15px auto" : 0 }}
      className={`${"common-btn"} ${isPrimary
        ? "primary-btn"
        : isWarning
          ? "red-btn"
          : isSecondary
            ? "secondary-btn"
            : ""
        } ${hasBorder ? "btn-border" : ""} ${isLarge ? "large-btn" : ""} ${isBold ? "bold-text" : ""
        } ${applyMinWidth ? "minWidth" : ""} ${isSmall ? "small-btn" : ""
        } ${className}
      `}
      onClick={onClick}
      {...args}
    >
      {children}
    </button>
  );
};

export default CommonButton;
