import { useSelector } from "react-redux";
import loading from "@/assets/images/gif/loader.gif";
const PageLoader = () => {
  const { pageLoader } = useSelector((state) => state.login);
  return (
    <div className={`loader ${pageLoader ? "show" : ""}`}>
      <img src={loading} alt="Loading..." width={"60px"} />
    </div>
  );
};
export default PageLoader;
