
import { nisystBlue } from "@/constants/images"

const AppLoader = () => {
  return (
    <div className="full-screen d-flex align-items-center justify-content-center flex-column">
      <img src={nisystBlue} alt="TFG logo" className="avatar-lg" />
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default AppLoader
