import { axiosNisystAdmin } from "@/services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ARCHIVE_PRODUCT,
  CHECK_ALL_PRODUCT,
  DELETE_PRODUCT,
  GET_ALL_SUPPLIER_LIST,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_COMPANIES_LIST,
  GET_PRODUCT_LIST,
  UPSERT_PRODUCT,
} from "@/services/url";
import { toast } from "react-toastify";

const productState = {
  loading: false,
  isProductsLoading: false,
  productCodeList: [],
  productList: [],
  productDetails: {},
  supplierList: [],
  productCompanyList: null,
  productCompanyLoading: false,
  productCompanyFilters: {
    search: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalCount: 0,
    },
    sorting: [],
  },
  filters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      totalCount: 0,
    },
    sorting: [],
    status: { label: "Active", value: 1 },
  },
};

//get products list
export const getProductsList = createAsyncThunk(
  `products/getProductsList`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(GET_PRODUCT_LIST, payload);
      if (response?.data) {
        callback();
        return response.data;
      }
    } catch (err) {
      callback();
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Company Product
export const getProductCompanyList = createAsyncThunk(
  `companies/getProductCompanyList`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(
        GET_PRODUCT_COMPANIES_LIST,
        payload
      );
      if (!!response?.data) {
        callback();
        return response.data?.data;
      }
    } catch (err) {
      if (err?.status !== 401 || err?.status !== 400) {
        callback();
      }
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
export const getProductByID = createAsyncThunk(
  `products/getProductByID`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_PRODUCT_BY_ID, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
export const getAllSupplierList = createAsyncThunk(
  `products/getAllSupplierList`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.get(GET_ALL_SUPPLIER_LIST);
      return response.data?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//delete product
export const deleteProduct = createAsyncThunk(
  `products/deleteProduct`,
  async (payload, thunkAPI) => {
    const { callback = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(DELETE_PRODUCT, payload);
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Archive Prodcut
export const archiveProduct = createAsyncThunk(
  `products/archiveProduct`,
  async (payload, thunkAPI) => {
    try {
      const { callback } = payload;
      const response = await axiosNisystAdmin.post(ARCHIVE_PRODUCT, payload);
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

//Upsert product
export const upsertProduct = createAsyncThunk(
  `products/upsertProduct`,
  async (payload, thunkAPI) => {
    try {
      const { callback } = payload;
      const response = await axiosNisystAdmin.post(UPSERT_PRODUCT, payload);
      if (!!response?.data) {
        callback();
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
//Check  product code
export const checkProductCode = createAsyncThunk(
  `products/checkProductCode`,
  async (payload, thunkAPI) => {
    const { callback } = payload;
    try {
      const response = await axiosNisystAdmin.post(CHECK_ALL_PRODUCT, payload);
      if (!!response?.data) {
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: productState,
  reducers: {
    setProductsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setProductsSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setProductsPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setProductsSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setProductsFilters: (state, action) => {
      state.filters.status = action.payload;
    },
    // Company Products
    setProductCompanyLoading: (state, action) => {
      state.productCompanyLoading = action.payload;
    },
    setProductCompanySearchQuery: (state, action) => {
      state.productCompanyFilters.searchQuery = action.payload;
    },
    setProductCompanyPagination: (state, action) => {
      state.productCompanyFilters.pagination = action.payload;
    },
    setProductCompanySorting: (state, action) => {
      state.productCompanyFilters.sorting = action.payload;
    },
    setProductCompanyFilters: (state, action) => {
      state.productCompanyFilters = {
        ...state.productCompanyFilters,
        ...action.payload,
      };
    },
    resetProductState: () => {
      return {
        ...productState,
      };
    },
  },
  extraReducers: (builder) => {
    // Products List
    builder.addCase(getProductsList.pending, (state) => {
      state.isProductsLoading = true;
    });
    builder.addCase(getProductsList.fulfilled, (state, action) => {
      state.isProductsLoading = false;
      state.productList = action.payload?.data?.records;
      state.filters.totalCount = action.payload?.data?.totalRecords;
    });
    builder.addCase(getProductsList.rejected, (state) => {
      state.isProductsLoading = false;
      state.productList = [];
    });
    // Company Product List
    builder.addCase(getProductCompanyList.pending, (state) => {
      state.productCompanyLoading = true;
    });
    builder.addCase(getProductCompanyList.fulfilled, (state, action) => {
      state.productCompanyLoading = false;
      state.productCompanyList = action?.payload?.records;
      state.productCompanyFilters.totalCount = action?.payload?.totalRecords;
    });
    builder.addCase(getProductCompanyList.rejected, (state) => {
      state.productCompanyLoading = false;
      state.productCompanyList = [];
    });
    // Product Details
    builder.addCase(getProductByID.pending, (state) => {
      state.isProductsLoading = true;
      state.productDetails = {};
    });
    builder.addCase(getProductByID.fulfilled, (state, action) => {
      state.isProductsLoading = false;
      state.productDetails = action.payload.data;
    });
    builder.addCase(getProductByID.rejected, (state) => {
      state.isProductsLoading = false;
      state.productDetails = {};
    });
    // Delete Product
    builder.addCase(deleteProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProduct.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProduct.rejected, (state) => {
      state.loading = false;
    });
    // Delete Product
    builder.addCase(checkProductCode.pending, (state) => {
      state.productCodeList = [];
    });
    builder.addCase(checkProductCode.fulfilled, (state, action) => {
      state.productCodeList = action?.payload;
    });
    builder.addCase(checkProductCode.rejected, (state) => {
      state.productCodeList = [];
    });
    // Upsert Product
    builder.addCase(upsertProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertProduct.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(upsertProduct.rejected, (state) => {
      state.loading = false;
    });
    // Supplier List
    builder.addCase(getAllSupplierList.pending, (state) => {
      state.isProductsLoading = true;
      state.supplierList = [];
    });
    builder.addCase(getAllSupplierList.fulfilled, (state, action) => {
      state.isProductsLoading = false;
      state.supplierList = action.payload;
    });
    builder.addCase(getAllSupplierList.rejected, (state) => {
      state.isProductsLoading = false;
      state.supplierList = [];
    });

    /* Reset entire state */
    builder.addCase(resetProductState, () => {
      return productState;
    });
  },
});
export const {
  setProductsSearchQuery,
  setProductsPagination,
  setProductsSorting,
  setProductsFilters,
  setProductsLoading,
  resetProductState,
  setProductCompanyLoading,
  setProductCompanySearchQuery,
  setProductCompanyPagination,
  setProductCompanySorting,
  setProductCompanyFilters,
} = productSlice.actions;
export default productSlice.reducer;
