import CommonButton from "@/components/common/buttons/CommonButton";
import CommonInputTextField from "@/components/common/inputTextField/CommonInputTextField";
import CommonTextareaField from "@/components/common/inputTextField/CommonTextareaField";
import Loader from "@/components/common/loaders/Loader";
import CommonModal from "@/components/common/modals/CommonModal";
import {
  descriptionRequired,
  titleRequired,
} from "@/constants/SchemaValidation";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { upsertContactUs } from "../profile/slice/ProfileSlice";

const ContactUs = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const { profileDetail } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const initialValues = {
    contactUSId: "",
    email: profileDetail?.email,
    title: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string(),
    title: Yup.string().trim().required(titleRequired),
    description: Yup.string().trim().required(descriptionRequired),
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      await dispatch(upsertContactUs(values)).then((res) => {
        if (res?.payload?.isSuccess) {
          setLoading(false);
          setIsOpen(false);
        } else {
          setLoading(false);
        }
      });
    } catch (err) {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const {
    values,
    validateField,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
  });

  useEffect(() => {
    resetForm({
      values: initialValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div>
      <CommonModal
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(!isOpen);
        }}
        className="common-modal-title"
        maxWidth="650px"
        title="Contact Us"
      >
        <form onSubmit={handleSubmit}>
          <div className="w-100">
            <div className="row">
              <CommonInputTextField
                name="email"
                placeHolder="Enter email"
                labelName="Email Address"
                isDisabled
                value={values?.email}
              />
            </div>
            <div className="row">
              <CommonInputTextField
                name="title"
                placeHolder="Enter title"
                labelName="Title"
                isInvalid={!!errors.title}
                errorText={errors.title}
                onChange={(e) => {
                  setFieldValue("title", e.target.value).then(() =>
                    validateField("title")
                  );
                }}
                required
                value={values?.title}
              />
            </div>
            <div className="row">
              <CommonTextareaField
                name="description"
                placeHolder="Enter description"
                labelName="Description"
                isInvalid={!!errors.description}
                errorText={errors.description}
                onChange={(e) => {
                  setFieldValue("description", e.target.value).then(() =>
                    validateField("description")
                  );
                }}
                required
                value={values?.description}
              />
            </div>

            <div>
              <div className="d-flex justify-content-center gap-4 contract-btn cdn_margin_top">
                <CommonButton
                  className="small-btn position-relative cancle_border_btn"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </CommonButton>
                <CommonButton
                  className="small-btn position-relative"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <Loader isRelative={false} /> : "Submit"}
                </CommonButton>
              </div>
            </div>
          </div>
        </form>
      </CommonModal>
    </div>
  );
};

export default ContactUs;
