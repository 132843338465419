import { axiosNisystAdmin } from "@/services/api";
import {
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SHOP_CONTACT_DETAILS,
  GET_ALL_CUSTOMERS,
  GET_ALL_CUSTOMER_SHOP_ADDITIONAL_LIST,
  GET_ALL_CUSTOMER_SHOP_CONTACT_LIST,
  GET_CUSTOMER_BY_ID,
  IMPORT_CUSTOMER,
  SUSPEND_CUSTOMER,
  UPSERT_CUSTOMER,
  UPSERT_CUSTOMER_SHOP_CONTACT,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const customerState = {
  customers: null,
  loading: false,
  error: null,
  totalCount: 0,
  status: {
    label: "Active",
    value: 1,
  },
  customerDetails: null,
  filters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
  customerContact: {
    customerContactList: [],
    customerContactData: {},
    loading: true,
    filters: {
      sorting: [],
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      search: "",
    },
  },
  customerAdditionalInfo: {
    customerAdditionalInfoList: [],
    customerAdditionalInfoData: {},
    loading: true,
    filters: {
      sorting: [],
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      search: "",
    },
  },
};

export const getAllCustomers = createAsyncThunk(
  `customerThunk/getAllCustomers`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_ALL_CUSTOMERS, payload);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const importCustomer = createAsyncThunk(
  `customerThunk/importCustomer`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(IMPORT_CUSTOMER, payload);
      if (response?.data) {
        toast.success(response?.data?.message);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  `customerThunk/deleteCustomer`,
  async (payload, thunkAPI) => {
    const { callback = () => {}, handleDeleteCustomer = () => {} } = payload;
    try {
      const response = await axiosNisystAdmin.post(DELETE_CUSTOMER, payload);
      if (!!response?.data) {
        callback(response?.data);
        handleDeleteCustomer(response?.data);
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getCustomerById = createAsyncThunk(
  `customerThunk/getCustomerById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(GET_CUSTOMER_BY_ID, payload);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const upsertCustomer = createAsyncThunk(
  `customerThunk/upsertCustomer`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(UPSERT_CUSTOMER, payload);
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const activeDeActiveCustomer = createAsyncThunk(
  `customerThunk/activeDeActiveCustomer`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(SUSPEND_CUSTOMER, payload);
      return response?.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

// CUSTOMER CONTACT
export const fetchCustomerContact = createAsyncThunk(
  `customerThunk/fetchCustomerContact`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ALL_CUSTOMER_SHOP_CONTACT_LIST,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const upsertCustomerContact = createAsyncThunk(
  `customer/upsertCustomerContact`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_CUSTOMER_SHOP_CONTACT,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteCustomerContact = createAsyncThunk(
  `customer/deleteCustomerContact`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_CUSTOMER_SHOP_CONTACT_DETAILS,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

// CUSTOMER ADDITIONAL DETAILS
export const fetchCustomerAdditional = createAsyncThunk(
  `customer/fetchCustomerAdditional`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ALL_CUSTOMER_SHOP_ADDITIONAL_LIST,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState: customerState,
  reducers: {
    setCustomerLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCustomerSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setCustomerPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setCustomerSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setCustomerStatus: (state, action) => {
      state.status = action.payload;
    },

    // Customer Contact
    setCustomerContactLoading: (state, action) => {
      state.customerContact.loading = action.payload;
    },
    setCustomerContactSearchQuery: (state, action) => {
      state.customerContact.filters.search = action.payload;
    },
    setCustomerContactSorting: (state, action) => {
      state.customerContact.filters.sorting = action.payload;
    },
    setCustomerContactPagination: (state, action) => {
      state.customerContact.filters.pagination = action.payload;
    },

    // Customer  Additional Detail
    setCustomerAdditionalLoading: (state, action) => {
      state.customerAdditionalInfo.loading = action.payload;
    },
    setCustomerAdditionalSearchQuery: (state, action) => {
      state.customerAdditionalInfo.filters.search = action.payload;
    },
    setCustomerAdditionalSorting: (state, action) => {
      state.customerAdditionalInfo.filters.sorting = action.payload;
    },
    setCustomerAdditionalPagination: (state, action) => {
      state.customerAdditionalInfo.filters.pagination = action.payload;
    },

    resetCustomerState: () => {
      return {
        ...customerState,
      };
    },
  },

  extraReducers: (builder) => {
    /*  Get All Customers */
    builder.addCase(getAllCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCustomers.fulfilled, (state, action) => {
      state.loading = false;
      state.totalCount = action.payload?.data?.totalRecords;
      state.customers = action.payload?.data?.records;
    });
    builder.addCase(getAllCustomers.rejected, (state) => {
      state.loading = false;
      state.customers = null;
    });

    /* Get Customer By Id */
    builder.addCase(getCustomerById.pending, (state) => {
      state.loading = true;
      state.customerDetails = null;
    });
    builder.addCase(getCustomerById.fulfilled, (state, action) => {
      state.loading = false;
      state.customerDetails = action.payload?.data;
    });
    builder.addCase(getCustomerById.rejected, (state) => {
      state.loading = false;
      state.customerDetails = null;
    });

    /* Get Customer Contact*/
    builder.addCase(fetchCustomerContact.pending, () => {});
    builder.addCase(fetchCustomerContact.fulfilled, (state, action) => {
      state.customerContact.customerContactList =
        action.payload.data?.data?.records;
      state.customerContact.customerContactData = action.payload.data?.data;
      state.customerContact.loading = false;
    });
    builder.addCase(fetchCustomerContact.rejected, (state) => {
      state.customerContact.customerContactList = [];
      state.customerContact.loading = false;
    });

    /* Get Customer  Additioal*/
    builder.addCase(fetchCustomerAdditional.pending, () => {});
    builder.addCase(fetchCustomerAdditional.fulfilled, (state, action) => {
      state.customerAdditionalInfo.customerAdditionalInfoList =
        action.payload.data?.data?.records;
      state.customerAdditionalInfo.customerAdditionalInfoData =
        action.payload.data?.data;
      state.customerAdditionalInfo.loading = false;
    });
    builder.addCase(fetchCustomerAdditional.rejected, (state) => {
      state.customerAdditionalInfo.customerAdditionalInfoList = [];
      state.customerAdditionalInfo.loading = false;
    });

    /* Reset entire state */
    builder.addCase(resetCustomerState, () => {
      return {
        ...customerState,
      };
    });
  },
});

export default customerSlice.reducer;

export const {
  setCustomerLoading,
  setCustomerSearchQuery,
  setCustomerPagination,
  setCustomerSorting,
  setCustomerStatus,
  setCustomerContactLoading,
  setCustomerContactSearchQuery,
  setCustomerContactSorting,
  setCustomerContactPagination,
  setCustomerAdditionalLoading,
  setCustomerAdditionalSearchQuery,
  setCustomerAdditionalSorting,
  setCustomerAdditionalPagination,
  resetCustomerState,
} = customerSlice.actions;
