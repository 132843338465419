/* eslint-disable no-empty-pattern */
import { axiosNisystAdmin } from "@/services/api";
import {
  DELETE_CUSTOMER_SHOP_ADDITIONAL_DETAILS,
  ADD_EDIT_CUSTOMER_SHOP,
  DELETE_SHOP_BY_ID,
  GET_ALL_CUSTOMER_SHOP_ADDITIONAL_LIST,
  GET_ALL_CUSTOMER_SHOP_CONTACT_LIST,
  GET_ALL_CUSTOMER_SHOP_LIST,
  GET_CUSTOMER_SHOP_BY_ID,
  GET_MASTER_DATA_FOR_ADDITIONAL_DETAIL,
  UPSERT_CUSTOMER_SHOP_ADDITIONAL_DETAILS,
  UPSERT_CUSTOMER_SHOP_CONTACT,
  DELETE_CUSTOMER_SHOP_CONTACT_DETAILS,
  IMPORT_CUSTOMER_SHOP,
  UPLOAD_CONTACT_SHEET,
  UPLOAD_ADDITIONAL_SHEET,
  CUSTOMER_SHOP_STATUS,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerShopList: [],
  customerShopData: {},
  customerShopById: {},
  customerShopByIdLoader: false,
  loading: true,
  addEditShopLoad: false,
  filters: {
    sorting: [],
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    search: "",
  },
  customerShopContact: {
    customerShopContactList: [],
    customerShopContactData: {},
    loading: true,
    filters: {
      sorting: [],
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      search: "",
    },
  },
  customerShopAdditionalInfo: {
    masterDataAdditionalDetaill: [],
    customerShopAdditionalInfoList: [],
    customerShopAdditionalInfoData: {},
    loading: true,
    filters: {
      sorting: [],
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      search: "",
    },
  },
  importShopLoad: false,
  importedCustomerShop: false,
};
//import customer shop
export const importCustomerShop = createAsyncThunk(
  `customerShop/importCustomerShop`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        `${IMPORT_CUSTOMER_SHOP}?CustomerId=${payload.id}`,
        payload.UploadedExcel
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

//UPSERY CUSTOMER SHOP
export const addEditCustomerShop = createAsyncThunk(
  `customerShop/addEditCustomerShop`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        ADD_EDIT_CUSTOMER_SHOP,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

// Fetch customer shop
export const fetchCustomerShop = createAsyncThunk(
  `customerShop/fetchCustomerShop`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ALL_CUSTOMER_SHOP_LIST,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const fetchCustomerShopById = createAsyncThunk(
  `customerShop/fetchCustomerShopById`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_CUSTOMER_SHOP_BY_ID,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteShop = createAsyncThunk(
  `customerShop/deleteShop`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(DELETE_SHOP_BY_ID, payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

// CUSTOMER SHOP CONTACT
export const fetchCustomerShopContact = createAsyncThunk(
  `customerShop/fetchCustomerShopContact`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ALL_CUSTOMER_SHOP_CONTACT_LIST,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const upsertShopContact = createAsyncThunk(
  `customerShop/upsertShopContact`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_CUSTOMER_SHOP_CONTACT,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteShopContact = createAsyncThunk(
  `customerShop/deleteShopContact`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_CUSTOMER_SHOP_CONTACT_DETAILS,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

// CUSTOMER SHOP ADDITIONAL DETAIL
export const fetchCustomerShopAdditional = createAsyncThunk(
  `customerShop/fetchCustomerShopAdditional`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ALL_CUSTOMER_SHOP_ADDITIONAL_LIST,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getMasterDataAdditionalDeatil = createAsyncThunk(
  `customerShop/getMasterDataAdditionalDeatil`,
  async () => {
    try {
      const response = await axiosNisystAdmin.get(
        GET_MASTER_DATA_FOR_ADDITIONAL_DETAIL
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const upsertAdditionalDetails = createAsyncThunk(
  `customerShop/upsertAdditionalDetails`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPSERT_CUSTOMER_SHOP_ADDITIONAL_DETAILS,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteShopAdditional = createAsyncThunk(
  `customerShop/deleteShopAdditional`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        DELETE_CUSTOMER_SHOP_ADDITIONAL_DETAILS,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);
export const handleImportContact = createAsyncThunk(
  `import/handleImportContact`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPLOAD_CONTACT_SHEET,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);
export const handleImportAdditional = createAsyncThunk(
  `import/handleImportAdditional`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        UPLOAD_ADDITIONAL_SHEET,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const handleShopStatus = createAsyncThunk(
  `shop/handleShopStatus`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        CUSTOMER_SHOP_STATUS,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

const CustomerShopSlice = createSlice({
  name: "customerShop",
  initialState: initialState,
  reducers: {
    // Customer Shop
    setCustomerShopLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCustomerShopSearchQuery: (state, action) => {
      state.filters.search = action.payload;
    },
    setCustomerShopSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setCustomerShopPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setCustomerShopByIdLoader: (state, action) => {
      state.customerShopByIdLoader = action.payload;
    },

    // Customer Shop Contact
    setCustomerShopContactLoading: (state, action) => {
      state.customerShopContact.loading = action.payload;
    },
    setCustomerShopContactSearchQuery: (state, action) => {
      state.customerShopContact.filters.search = action.payload;
    },
    setCustomerShopContactSorting: (state, action) => {
      state.customerShopContact.filters.sorting = action.payload;
    },
    setCustomerShopContactPagination: (state, action) => {
      state.customerShopContact.filters.pagination = action.payload;
    },

    // Customer Shop Additional Detail
    setCustomerShopAdditionalLoading: (state, action) => {
      state.customerShopAdditionalInfo.loading = action.payload;
    },
    setCustomerShopAdditionalSearchQuery: (state, action) => {
      state.customerShopAdditionalInfo.filters.search = action.payload;
    },
    setCustomerShopAdditionalSorting: (state, action) => {
      state.customerShopAdditionalInfo.filters.sorting = action.payload;
    },
    setCustomerShopAdditionalPagination: (state, action) => {
      state.customerShopAdditionalInfo.filters.pagination = action.payload;
    },

    // reset state
    resetAllCustomerShop: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerShop.pending, () => {});
    builder.addCase(fetchCustomerShop.fulfilled, (state, action) => {
      state.customerShopList = action.payload.data?.data?.records;
      state.customerShopData = action.payload.data?.data;
      state.loading = false;
    });
    builder.addCase(fetchCustomerShop.rejected, (state) => {
      state.customerShopList = [];
      state.loading = false;
    });

    /* Get customer shop by id */
    builder.addCase(fetchCustomerShopById.pending, () => {});
    builder.addCase(fetchCustomerShopById.fulfilled, (state, action) => {
      state.customerShopById = action?.payload?.data?.data;
      state.customerShopByIdLoader = false;
    });
    builder.addCase(fetchCustomerShopById.rejected, (state) => {
      state.customerShopByIdLoader = false;
    });

    /* Get Customer Shop Contact*/
    builder.addCase(fetchCustomerShopContact.pending, () => {});
    builder.addCase(fetchCustomerShopContact.fulfilled, (state, action) => {
      state.customerShopContact.customerShopContactList =
        action.payload.data?.data?.records;
      state.customerShopContact.customerShopContactData =
        action.payload.data?.data;
      state.customerShopContact.loading = false;
    });
    builder.addCase(fetchCustomerShopContact.rejected, (state) => {
      state.customerShopContact.customerShopContactList = [];
      state.customerShopContact.loading = false;
    });

    /* Get Master Data For Customer Shop Additioal*/
    builder.addCase(getMasterDataAdditionalDeatil.pending, () => {});
    builder.addCase(
      getMasterDataAdditionalDeatil.fulfilled,
      (state, action) => {
        state.customerShopAdditionalInfo.masterDataAdditionalDetaill =
          action.payload?.data?.data;
      }
    );
    builder.addCase(getMasterDataAdditionalDeatil.rejected, (state) => {
      state.customerShopAdditionalInfo.masterDataAdditionalDetaill = [];
    });

    /* Get Customer Shop Additioal*/
    builder.addCase(fetchCustomerShopAdditional.pending, () => {});
    builder.addCase(fetchCustomerShopAdditional.fulfilled, (state, action) => {
      state.customerShopAdditionalInfo.customerShopAdditionalInfoList =
        action.payload.data?.data?.records;
      state.customerShopAdditionalInfo.customerShopAdditionalInfoData =
        action.payload.data?.data;
      state.customerShopAdditionalInfo.loading = false;
    });
    builder.addCase(fetchCustomerShopAdditional.rejected, (state) => {
      state.customerShopAdditionalInfo.customerShopAdditionalInfoList = [];
      state.customerShopAdditionalInfo.loading = false;
    });

    //upsert customer shop
    builder.addCase(addEditCustomerShop.pending, (state) => {
      state.addEditShopLoad = true;
    });
    builder.addCase(addEditCustomerShop.fulfilled, (state) => {
      state.addEditShopLoad = false;
    });
    builder.addCase(addEditCustomerShop.rejected, (state) => {
      state.addEditShopLoad = false;
    });
    builder.addCase(importCustomerShop.pending, (state) => {
      state.importShopLoad = true;
      state.importedCustomerShop = false;
    });
    builder.addCase(importCustomerShop.fulfilled, (state) => {
      state.importShopLoad = false;
      state.importedCustomerShop = true;
    });
    builder.addCase(importCustomerShop.rejected, (state) => {
      state.importShopLoad = false;
      state.importedCustomerShop = false;
    });
    /* Reset entire state */
    builder.addCase(resetAllCustomerShop, () => {
      return {
        ...initialState,
      };
    });
  },
});
export const {
  setCustomerShopLoading,
  setCustomerShopSearchQuery,
  setCustomerShopSorting,
  setCustomerShopPagination,
  resetCustomerShop,
  setCustomerShopByIdLoader,
  setCustomerShopContactLoading,
  setCustomerShopContactSearchQuery,
  setCustomerShopContactSorting,
  setCustomerShopContactPagination,
  setCustomerShopAdditionalLoading,
  setCustomerShopAdditionalSearchQuery,
  setCustomerShopAdditionalSorting,
  setCustomerShopAdditionalPagination,
  resetAllCustomerShop,
} = CustomerShopSlice.actions;
export default CustomerShopSlice.reducer;
