/* eslint-disable no-empty-pattern */

import { axiosNisystAdmin } from "@/services/api";
import { ADD_EDIT_PROJECTS, DELETE_PROJECTS, GET_PROJECT_BY_ID, LIST_PROJECTS } from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    projectList: [],
    projectData: {},
    projectIdData: {},
    projectIdDataLoader: true,
    loading: true,
    filters: {
        sorting: [],
        pagination: {
            pageIndex: 0,
            pageSize: 10,
            totalRecords: 0,
        },
        search: "",
    }
};

export const fetchProjects = createAsyncThunk(
    `projects/fetchProjects`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(LIST_PROJECTS, payload);
            return response;
        } catch (err) {
            return err
        }
    }
);

export const addEditProjects = createAsyncThunk(
    `projects/addEditProjects`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(ADD_EDIT_PROJECTS, payload);
            return response;
        } catch (err) {
            return err
        }
    }
);

export const deleteProjects = createAsyncThunk(
    `projects/deleteProjects`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(DELETE_PROJECTS, payload);
            return response;
        } catch (err) {
            return err
        }
    }
);

export const getProjectById = createAsyncThunk(
    `projects/getProjectById`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(GET_PROJECT_BY_ID, payload);
            return response;
        } catch (err) {
            return err
        }
    }
);


const ProjectSlice = createSlice({
    name: "projects",
    initialState: initialState,
    reducers: {
        setProjectsLoading: (state, action) => {
            state.loading = action.payload;
        },
        setProjectsSearchQuery: (state, action) => {
            state.filters.search = action.payload
        },
        setProjectsSorting: (state, action) => {
            state.filters.sorting = action.payload;
        },
        setProjectsPagination: (state, action) => {
            state.filters.pagination = action.payload;
        },
 resetProjectState: () => {
      return initialState;
    },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProjects.pending, () => {
        });
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            state.projectList = action.payload.data?.data?.records;
            state.projectData = action.payload.data?.data;
            state.loading = false;
        });
        builder.addCase(fetchProjects.rejected, (state) => {
            state.projectList = [];
            state.loading = false;
        });

        builder.addCase(getProjectById.pending, () => {
        });
        builder.addCase(getProjectById.fulfilled, (state, action) => {
            state.projectIdData = action.payload.data?.data
            state.projectIdDataLoader = false
        });
        builder.addCase(getProjectById.rejected, (state) => {
            state.projectList = [];
            state.projectIdDataLoader = false
        });
 /* Reset entire state */
    builder.addCase(resetProjectState, () => {
      return {
        ...initialState,
      };
    });

    },
});
export const {
  setProjectsSearchQuery,
  setProjectsLoading,
  setProjectsSorting,
  setProjectsPagination,
  resetProjectState,
} = ProjectSlice.actions;
export default ProjectSlice.reducer;

