import moment from "moment-timezone";
import CryptoJS from "crypto-js";
export default function convertTimeToReadableFormat(hours, minutes) {
  // const [hours, minutes] = timeString.split(".");
  const parsedHours = parseInt(hours, 10);
  const parsedMinutes = parseInt(minutes, 10);
  const hourLabel = parsedHours === 1 ? "hour" : "hours";
  const minuteLabel = parsedMinutes === 1 ? " minute" : " minutes";
  return `${parsedHours || 0} ${hourLabel} ${
    parsedMinutes > 0 ? "and " + parsedMinutes + minuteLabel : ""
  } `;
}

export const formatDateTime = (dateTime) => {
  if (dateTime) {
    return moment.utc(dateTime).local().format("DD/MM/YYYY hh:mm A");
  } else {
    return null;
  }
};

export const formatDateTimeLocal = (dateTime) => {
  return moment.utc(dateTime).format("DD/MM/YYYY hh:mm A");
};

export const formatTimeline = (
  typicalTimelineHours,
  typicalTimelineMinutes
) => {
  // Handle the case where both hours and minutes are 0
  if (!typicalTimelineHours && !typicalTimelineMinutes) {
    return "0 Hour";
  }

  // Handle case where only minutes should be displayed (0 hours and more than 0 minutes)
  if (typicalTimelineHours === 0 && typicalTimelineMinutes > 0) {
    const minuteLabel = typicalTimelineMinutes === 1 ? "Minute" : "Minutes";
    return `${typicalTimelineMinutes} ${minuteLabel}`;
  }

  // Handle case where only hours should be displayed (more than 0 hours and 0 minutes)
  if (typicalTimelineMinutes === 0 && typicalTimelineHours > 0) {
    const hourLabel = typicalTimelineHours === 1 ? "Hour" : "Hours";
    return `${typicalTimelineHours} ${hourLabel}`;
  }

  // For other cases, display both hours and minutes
  const hourLabel = typicalTimelineHours === 1 ? "Hour" : "Hours";
  const minuteLabel = typicalTimelineMinutes === 1 ? "Minute" : "Minutes";

  return `${typicalTimelineHours} ${hourLabel} ${typicalTimelineMinutes} ${minuteLabel}`;
};

export const convertTo12Hour = (time) => {
  return moment(time, "HH:mm").format("h:mm A");
};

export const getFormattedDateTime = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");

  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const downloadSheet = (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const generateRandomString = () => {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=";
  const length = 10;

  let randomString = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  const timestamp = new Date().getTime();
  const finalString = randomString + "_NISYST_" + timestamp;

  return finalString;
};

const key = CryptoJS.enc.Utf8.parse(
  import.meta.env.VITE_REACT_APP_ENCRYPTION_KEY
);
const iv = CryptoJS.enc.Utf8.parse(
  import.meta.env.VITE_REACT_APP_ENCRYPTION_IV
);

export const encryptString = (string) => {
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(string), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};
