export const ServiceNameRequired = "Please enter service name.";
export const SkillNameRequired = "Please enter title.";
export const SelectProduct = "Please select product";
export const SerialNo = "Please enter serial no";
export const YellowSticker = "Please enter yellow sticker no";
export const CompanyNameRequired = "Please enter company name.";
export const FirstNameRequired = "Please enter first name.";
export const LastNameRequired = "Please enter last name.";
export const EmailRequired = "Please enter email.";
export const EmailValidation = "Please enter valid email address.";
export const InvalidEmailFormat = "Please enter valid email.";
export const MobileRequired = "Please enter mobile number.";
export const Address1Required = "Please enter address.";
export const TelephoneRequired = "Please enter telephone number.";
export const TelephoneValidation = "Please enter valid telephone number.";
export const MobileValidation = "Please enter valid mobile number.";
export const StreetRequired = "Please enter street address.";
export const CityRequired = "Please enter city/town.";
export const CountyRequired = "Please enter county.";
export const StateRequired = "Please enter state.";
export const PostCodeRequired = "Please enter postcode.";
export const TaxCodeRequired = "Please enter tax code.";
export const VATRequired = "Please enter VAT registration number.";
export const CompanyCodeRequired = "Please enter company code.";
export const BusinessRegisterRequired =
  "Please enter business registration number.";
export const TaxIdentificationRequired =
  "Please enter tax identification number.";
export const FileValidation = "File is too large.";
export const CompanyTypeRequired = "Please enter company type.";
export const WebsiteRequired = "Please enter website url.";
export const WebsiteValidation = "Please enter valid website url.";
export const CompanyRequired = "Please select company.";
export const MobileNumberRequired = "Please enter mobile number.";
export const PostCodeLimitation = "Postcode must not exceed 50 characters.";
export const CharacterLimitation = "Skill must not exceed 50 characters.";
export const BusinessNameRequired = "Please enter business name.";
export const AccountNumberRequired = "Please enter account number.";
export const NominalCodeRequired = "Please enter nominal code.";
export const NominaleCodeLimit = "Nominal code must be 8 characters.";
export const departmentRequired = "Please select department.";
export const departmentInputRequired = "Please enter department.";
export const companyRequired = "Please select company.";
export const permissionRequired = "Please select permission profile.";
export const positionRequired = "Please enter position.";
export const titleRequired = "Please enter title.";
export const descriptionRequired = "Please enter description.";
export const timelineRequired = "Please select timeline.";
export const startDateRequired = "Please enter start date.";
export const hoursRequired = "Please enter hours.";
export const minutesRequired = "Please enter minutes.";
export const OtpRequired = "Please enter verification code.";
export const OtpValidation = "Verification code must be a 6-digit number.";
export const WebsiteLimitation = "Website must not exceed 250 characters.";
export const ImageValidation = "Only image files are allowed.";
// DefaultErrorMessage
export const somethingWentWrong =
  "Something went wrong,please try again later.";
// Maximum limit
export const maximumLimit = "Maximum 250 characters are allowed";
export const maximumLimit50 = "Maximum 50 characters are allowed";

//Change Password
export const passwordRequired = "Please enter password.";
export const oldPassword = "Please enter old password.";
export const newPassword = "Please enter new password.";
export const confirmNewPassword = "Please enter confirm password.";
export const passwordMatch = "Password and confirm password does not match.";
export const PasswordValidation =
  "Use at least 12 characters and a mix of symbols, numbers, upper and lower case letters for a strong password.";
export const maxPasswordLimit = "Password cannot exceed 16 characters.";

//shop
export const shopNameRequired = "Please enter shop name.";
export const shopOutletRequired = "Please enter shop outlet number.";
export const shopOutletLimit = "Shop outlet number cannot exceed 8 digits.";
export const shopTimeRequired = "Please select shop time.";
export const shopTimeLimit = "Please select atleast one shop timing.";

// Delivery notes
export const CustomerNumber = "Please select customer.";
export const OutletNumber = "Please select outlet.";
export const OrderNumber = "Please enter order number.";
export const OrderDate = "Please enter order date.";
export const InvoiceNumber = "Please enter invoice number.";
export const InvoiceDate = "Please enter invoice date.";
export const AdditionalNote = "Please enter note.";
export const CoureirNameRequired = "Please enter courier name.";
export const ConsignmentNumberRequired = "Please enter consignment number.";
export const ShippedDateRequired = "Please enter shipped date.";
export const ReceivedDateRequired = "Please enter received date.";
export const ReceivedConfirmDateRequired =
  "Please enter received confirm date.";
export const ReceivedConfirmByNisystRequired =
  "Please enter nisyst person name.";
export const ReceivedConfirmByCustomerRequired = "Please enter customer name.";

//Customer
export const customerNumberRequired = "Please enter customer number.";
export const nominalCodeRequired = "Please enter nominal code.";

// N/A
export const notAvailable = "N/A";
// Company
export const productCodeRequired = "Please enter product code.";
export const productNameRequired = "Please select product.";
export const quantityRequired = "Please enter at least one quantity.";
export const quantityMinValue = "Quantity must be greater than 0.";
export const serialNumberRequired = "Please enter serial number.";

// Additional Details
export const headerRequired = "Please select header detail.";
export const additionalDetailRequired = "Please enter additional details.";

// Task Templates
export const templateNameRequired = "Please enter template name.";
export const taskTitleRequired = "Please enter task name.";

// Job Module
export const projectSelectRequired = "Please select project.";
export const customerSelectRequired = "Please select customer.";
export const shopSelectRequired = "Please select shop.";
export const serviceSelectRequired = "Please select service.";
export const companySelectRequired = "Please select company.";
export const selectDueDateRequired = "Please select due date.";
export const compareDueDateToScheduleDate =
  "Due date must be greater than or equal to schedule date.";

export const questionRequired = "Please enter a question."
export const answerRequired = "Please enter an answer."

