import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const Layout = () => {
  const { isAuth } = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(window?.innerWidth >= 1200);
  const toggleSidebar = () => setIsOpen((prevState) => !prevState);

  useEffect(() => {
    const handleResize = () => setIsOpen(window?.innerWidth >= 1200);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document?.body?.classList?.toggle("sidebar-open", isOpen);
    return () => document?.body?.classList?.remove("sidebar-open");
  }, [isOpen]);

  return isAuth ? (
    <div className="wrapper d-flex align-items-stretch">
      <div className="app w-100">
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="content">
          <Header isOpen={isOpen} toggleSidebar={toggleSidebar} />
          <div className="main-part">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <Outlet />
    </div>
  );
};

export default Layout;
