import { Link } from "react-router-dom";
import { Tooltip, Zoom } from "@mui/material";
import ReactSvg from "@/components/common/reactSVG/ReactSvg";

const SidebarMenuItem = ({ pathname, link, handleItemClick, isOpen }) => {
  return (
    <li
      key={link?.name}
      className={pathname?.startsWith(link?.href) ? "active" : ""}
      onClick={() => handleItemClick(link?.href)}
    >
      <Tooltip
        title={link?.name}
        arrow
        TransitionComponent={Zoom}
        placement="right"
        disableHoverListener={isOpen}
      >
        <Link to={link?.href}>
          <ReactSvg
            src={link?.iconUrl}
            color_code={
              pathname?.startsWith(link?.href) ? "#007EC5" : "#282828"
            }
          />
          <span className="menu-item-text">{link?.name}</span>
        </Link>
      </Tooltip>
    </li>
  );
};

export default SidebarMenuItem;
