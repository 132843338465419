/* eslint-disable no-empty-pattern */
import { axiosNisystAdmin } from "@/services/api";
import { ADD_EDIT_SUPPLIER_CONTACT, DELETE_SUPPLIER_CONTACT_BY_ID, GET_SUPPLIER_CONTACT } from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    supplierContactList: [],
    supplierContactData: {},
    loading: true,
    filters: {
        sorting: [],
        pagination: {
            pageIndex: 0,
            pageSize: 10,
            totalRecords: 0,
        },
        search: "",
    }
};

export const fetchSupplierContact = createAsyncThunk(
    `supplierContact/fetchSupplierContact`,
    async (payload, thunkAPI) => {
        try {
            const response = await axiosNisystAdmin.post(GET_SUPPLIER_CONTACT, payload);
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
        }
    }
);
export const addEditSupplierContact = createAsyncThunk(
    `supplierContact/addEditSupplierContact`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(ADD_EDIT_SUPPLIER_CONTACT, payload);
            return response;
        } catch (err) {
            return err;
        }
    }
);
export const deleteSupplierContact = createAsyncThunk(
    `supplierContact/deleteSupplierContact`,
    async (payload, thunkAPI) => {
        try {
            const response = await axiosNisystAdmin.post(DELETE_SUPPLIER_CONTACT_BY_ID, payload);
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
        }
    }
);


const SupplierContactSlice = createSlice({
    name: "supplierContacts",
    initialState: initialState,
    reducers: {
        setSupplierContactLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSupplierContactSearchQuery: (state, action) => {
            state.filters.search = action.payload
        },
        setSupplierContactSorting: (state, action) => {
            state.filters.sorting = action.payload;
        },
        setSupplierContactPagination: (state, action) => {
            state.filters.pagination = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSupplierContact.pending, () => {
        });
        builder.addCase(fetchSupplierContact.fulfilled, (state, action) => {
            state.supplierContactList = action.payload.data?.data?.records;
            state.supplierContactData = action.payload.data?.data
            state.loading = false
        });
        builder.addCase(fetchSupplierContact.rejected, (state) => {
            state.supplierContactList = [];
            state.loading = false
        });
    },
});
export const { setSupplierContactLoading, setSupplierContactSearchQuery, setSupplierContactSorting, setSupplierContactPagination } = SupplierContactSlice.actions;
export default SupplierContactSlice.reducer;

