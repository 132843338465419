/* eslint-disable no-empty-pattern */
import { axiosNisystAdmin } from "@/services/api";
import { DELETE_FAQ, GET_FAQ_LIST, UPSERT_FAQ, } from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    faqList: [],
};

export const fetchFaq = createAsyncThunk(
    `faq/fetchFaq`,
    async () => {
        try {
            const response = await axiosNisystAdmin.get(GET_FAQ_LIST);
            return response;
        } catch (err) {
            return err
        }
    }
);

export const addEditFaq = createAsyncThunk(
    `faq/addEditFaq`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(UPSERT_FAQ, payload);
            return response;
        } catch (err) {
            return err
        }
    }
);

export const deleteFaq = createAsyncThunk(
    `faq/deleteFaq`,
    async (payload) => {
        try {
            const response = await axiosNisystAdmin.post(DELETE_FAQ, payload);
            return response;
        } catch (err) {
            return err
        }
    }
);




const FaqSlice = createSlice({
    name: "faq",
    initialState: initialState,
    reducers: {
        setUpdatedFaq: (state, action) => {
            state.faqList = action.payload;
        },
        resetFaqState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFaq.fulfilled, (state, action) => {
            state.faqList = action.payload?.data?.data || [];
        });
        builder.addCase(fetchFaq.rejected, () => {
        });


        /* Reset entire state */
        builder.addCase(resetFaqState, () => {
            return {
                ...initialState,
            };
        });

    },
});
export const {
    setUpdatedFaq,
    resetFaqState,
} = FaqSlice.actions;
export default FaqSlice.reducer;

