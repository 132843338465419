const routesConstants = {
  LOGIN: "login",
  HOME: "/dashboard",
  ADMINS: "/admins",
  ENGINEERS: "/engineers",
  STOCK_MOVEMENT: "/stock-movement",
  SKILLS: "/skills",
  COMPANIES: "/companies",
  SHOP: "/shops",
  GOODS_RECEIPT: "/good-receipt",
  GOOD_RECEIPT_CREATE:"/good-receipt/create/",
  SERVICES: "/services",
  DELIVERY_NOTES: "/delivery-notes",
  PRODUCTS: "/products",
  CUSTOMERS: "/customer",
  SUPPLIER: "/Suppliers",
  ADD_SUPPLIER: "/create",
  EDIT_SUPPLIER: "/edit-supplier",
  VIEW_SUPPLIER: "/view",
  TEMPLATES: "/templates",
  PROJECTS: "/projects",
  JOBS: "/jobs",
  PERMISSIONS_PROFILES: "/permissions-profiles",
  TWO_FACTOR_AUTH: "/two-factor-authentication",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
  UNAUTHORIZED: "unauthorized",
  _404: "*",
  FAQ: "/faq",
  ABOUT: "/about",
  SUPPORT: "/support",
  PRIVACY: "/privacy-policy",
  TERMS_AND_CONDITION: "/terms-condition",
  EDIT_PROFILE: "/edit-profile",
  PROFILE: "/my-profile",
  CHANGE_PASSWORD: "/change-password",
  CREATE: "create",
  EDIT: ":id/mode=edit",
  VIEW: ":id",
  SEARCH_VIEW: ":id/search",
  SEARCH: "search",
  ADDITIONAL_DETAILS_MASTER: "/additional-details-master",
  SOURCE_JOB:"create?source=job",
  EDIT_SOURCE_JOB:":id/mode=edit?source=job",
  ABOUT_US:'/about-us'
};
export default routesConstants;
