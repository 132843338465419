import { axiosNisystAdmin } from "@/services/api";
import { CHECK_ALL_STOCK_MOVEMENT, DELETE_STOCK_MOVEMENT, DELETE_STOCK_MOVEMENT_ITEM, GET_ALL_PRODUCTS, GET_STOCK_ITEMS_MOVEMENT,  GET_STOCK_ITEM_DETAILS_BY_ID, GET_STOCK_MOVEMENT, GET_STOCK_MOVEMENT_SERIAL_NUMBER_LIST, UPSERT_STOCK_MOVEMENT, UPSERT_STOCK_MOVEMENT_DETAILS, UPSERT_STOCK_MOVEMENT_DETAIL_SERIAL_NUMBER } from "@/services/url";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const stockState = {
  stockList: null,
  loading: false,
  error: null,
  totalCount: 0,
  status: 0,
  upsertLoading:false,
  stockDetails: null,
  stockItemList:null,
  stockItemListCount:0,
  productList:null,
  stockMovementId:"",
  serialNumberList:null,
  filters: {
    searchQuery: "",
    status:{ label: "LIV", value: 1 },
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
  StockItemFilters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    sorting: [],
  },
};
//Upsert product
export const getStockMovementList = createAsyncThunk(
  `stockMovement/getStockMovementList`,
  async (payload, thunkAPI) => {
    try {
      const {callback=()=>{}} = payload
      const response = await axiosNisystAdmin.post(GET_STOCK_MOVEMENT, payload);
      if(!!response?.data){
        callback()
        return response.data?.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
//Upsert product
export const getStockItemMovementList = createAsyncThunk(
  `stockMovement/getStockItemMovementList`,
  async (payload, thunkAPI) => {
    try {
      const {callback=()=>{}} = payload
      const response = await axiosNisystAdmin.post(GET_STOCK_ITEMS_MOVEMENT, payload);
      if(!!response?.data){
        callback()
        return response.data?.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
//Upsert product
export const upsertStockItem = createAsyncThunk(
  `stockMovement/upsertStockItem`,
  async (payload, thunkAPI) => {
    try {
      const {callback} = payload
      const response = await axiosNisystAdmin.post(UPSERT_STOCK_MOVEMENT, payload);
      if(!!response?.data){
       
        toast.success(response?.data?.message)
        callback(response?.data);
        return response.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
//Upsert product
export const upsertStockItemDetails = createAsyncThunk(
  `stockMovement/upsertStockItemDetails`,
  async (payload, thunkAPI) => {
    const {callback =()=>{}} = payload
    try {
      const response = await axiosNisystAdmin.post(UPSERT_STOCK_MOVEMENT_DETAILS, payload);
      if(!!response?.data){
        callback(true);
        toast.success(response?.data?.message)
        return response.data;
      }
    } catch (err) {
      callback(false)
      toast.error(err?.response?.data?.message || err.message)
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getStockItemDetails = createAsyncThunk(
  `stockMovement/getStockItemDetails`,
  async (payload, thunkAPI) => {
    try {
      const {callback=()=>{}} = payload
      const response = await axiosNisystAdmin.post(GET_STOCK_ITEM_DETAILS_BY_ID, payload);
      if(!!response?.data){
        callback()
        return response.data?.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

export const getAllProducts = createAsyncThunk(
  `stockMovement/getAllProducts`,
  async (payload, thunkAPI) => {
    try {

      const response = await axiosNisystAdmin.get(GET_ALL_PRODUCTS);
      if(!!response?.data){
        return response.data?.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Get serial number data 
export const getSerialNumberList = createAsyncThunk(
  `stockMovement/getSerialNumberList`,
  async (payload, thunkAPI) => {
    try {

      const response = await axiosNisystAdmin.post(GET_STOCK_MOVEMENT_SERIAL_NUMBER_LIST, payload);
      if(!!response?.data){
        return response.data?.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// upsert serial number data 
export const upsertSerialNumber = createAsyncThunk(
  `stockMovement/upsertSerialNumber`,
  async (payload, thunkAPI) => {
    try {
const {callback = () =>{}}= payload
      const response = await axiosNisystAdmin.post(UPSERT_STOCK_MOVEMENT_DETAIL_SERIAL_NUMBER, payload);
      if(!!response?.data){
        toast.success(response?.data?.message)
        callback()
        return response.data?.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
//Delete stock movement
export const deleteStockMovement = createAsyncThunk(
  `stockMovement/deleteStockMovement`,
  async (payload, thunkAPI) => {
    const {callback=()=>{}} = payload
    try {
      const response = await axiosNisystAdmin.post(DELETE_STOCK_MOVEMENT, payload);
      if(!!response?.data){
        callback(response?.data)
        return response.data?.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
//Delete stock movement item
export const deleteStockMovementItem = createAsyncThunk(
  `stockMovement/deleteStockMovementItem`,
  async (payload, thunkAPI) => {
    const {callback=()=>{}} = payload
    try {
     
      const response = await axiosNisystAdmin.post(DELETE_STOCK_MOVEMENT_ITEM, payload);
      if(!!response?.data){
        callback(response?.data);
        return response.data?.data;
      }
    } catch (err) {
      callback(err?.response?.data);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
// Check stock movement code
export const checkStockMovement = createAsyncThunk(
  `stockMovement/checkStockMovement`,
  async (payload, thunkAPI) => {
    const {callback=()=>{}} = payload
    try {
      const response = await axiosNisystAdmin.post(CHECK_ALL_STOCK_MOVEMENT, payload);
      if(!!response?.data){
        callback(response?.data)
        return response.data;
      }
    } catch (err) {
      callback(err?.response?.data)
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);
const stockSlice = createSlice({
  name: "stockMovement",
  initialState: stockState,
  reducers: {
    setStockSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setStockPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    setStockSorting: (state, action) => {
      state.filters.sorting = action.payload;
    },
    setStockItemId:(state, action)=>{
      state.stockMovementId = action.payload
    },
    setStockStatus:(state, action)=>{
      state.filters.status = action.payload
    },
    setStockDetails:(state, action)=>{
      state.stockDetails = action?.payload
    },
    // Stock Items
    setStockItemsSearchQuery: (state, action) => {
      state.StockItemFilters.searchQuery = action.payload;
    },
    setStockItemsPagination: (state, action) => {
      state.StockItemFilters.pagination = action.payload;
    },
    setStockItemsSorting: (state, action) => {
      state.StockItemFilters.sorting = action.payload;
    },
  resetStockState: () => {
        return {
          ...stockState,
        };
      },
  },

 extraReducers: (builder) => {
    // Stock Movement  List
    builder.addCase(getStockMovementList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStockMovementList.fulfilled, (state, action) => {
      state.loading = false;
      state.stockList = action?.payload?.records
      state.totalCount = action?.payload?.totalRecords
      ;
    });
    builder.addCase(getStockMovementList.rejected, (state) => {
      state.loading = false;
      state.stockList = []
    });
    // Stock Movement  List
    builder.addCase(getAllProducts.pending, (state) => {
      state.productList = [];
    });
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.productList = action?.payload;
    });
    builder.addCase(getAllProducts.rejected, (state) => {
      state.productList = []
    });
    // Stock Movement  List
    builder.addCase(getStockItemMovementList.pending, (state) => {
      state.loading = true;
      // state.stockItemList = [];
    });
    builder.addCase(getStockItemMovementList.fulfilled, (state, action) => {
      state.loading = false;
      state.stockItemList = action.payload?.records
      state.stockItemListCount = action.payload?.totalRecords
      ;
    });
    builder.addCase(getStockItemMovementList.rejected, (state) => {
      state.loading = false;
      state.stockItemList = [];
    });
    // Upsert Stock movement
    builder.addCase(upsertStockItem.pending, (state) => {
      state.stockMovementId = "";
      state.upsertLoading = true
    });
    builder.addCase(upsertStockItem.fulfilled, (state, action) => {
      state.stockMovementId = action?.payload?.data
      ;
      state.upsertLoading = false
    });
    builder.addCase(upsertStockItem.rejected, (state) => {
      state.stockMovementId =""
      state.upsertLoading = false
    });
    // Products List
    builder.addCase(getStockItemDetails.pending, (state) => {
      state.loading = true;
      state.stockDetails = null;
    });
    builder.addCase(getStockItemDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.stockDetails = action.payload;
      state.totalRecords = action.payload.totalRecords
      ;
    });
    builder.addCase(getStockItemDetails.rejected, (state) => {
      state.loading = false;
      state.stockDetails = null;
    });
    // serial number list
    builder.addCase(getSerialNumberList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSerialNumberList.fulfilled, (state, action) => {
      state.loading = false;
      state.serialNumberList = action.payload
      ;
    });
    builder.addCase(getSerialNumberList.rejected, (state) => {
      state.loading = false;
    });
    /* Reset entire state */
    builder.addCase(resetStockState, () => {
      return stockState;
    });
  },
});

export default stockSlice.reducer;

export const {
  setStockSearchQuery,
  setStockPagination,
  setStockSorting,
  setStockItemsSearchQuery,
  setStockItemsPagination,
  setStockItemsSorting,
  setStockItemId,
  setStockStatus,
  resetStockState,
  setStockDetails
} = stockSlice.actions;
