import { toast } from "react-toastify";
import { axiosNisystAdmin } from "@/services/api";
import {
  ADD_EDIT_ADDITIONAL_DETAIL,
  GET_ALL_ADDITIONAL_DETAILS,
  DELETE_ADDITIONAL_DETAIL,
} from "@/services/url";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { somethingWentWrong } from "@/constants/SchemaValidation";

const additionalDetailsMasterState = {
  loading: true,
  additionalDetailsList: [],
  additionalDetailsListData: {},
  filters: {
    searchQuery: "",
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    status: 1,
  },
};

//Get all additional Details list
export const getAdditionalDetails = createAsyncThunk(
  `TemplateThunk/getAdditionalDetails`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosNisystAdmin.post(
        GET_ALL_ADDITIONAL_DETAILS,
        payload
      );
      return response?.data;
    } catch (err) {
      const errorMessage = err?.response?.data?.message || err.message;
      toast.error(errorMessage || somethingWentWrong);
      return thunkAPI.rejectWithValue({
        statusCode: err?.response?.data?.statusCode,
        message: errorMessage,
      });
    }
  }
);

// Add/Edit additional Details
export const addEditAdditionalDetail = createAsyncThunk(
  `projects/addEditAdditionalDetail`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(
        ADD_EDIT_ADDITIONAL_DETAIL,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

// Delete additional Details
export const deleteAdditionalDetail = createAsyncThunk(
  `projects/deleteAdditionalDetail`,
  async (payload) => {
    try {
      const response = await axiosNisystAdmin.post(DELETE_ADDITIONAL_DETAIL, payload);
      return response;
    } catch (err) {
      return err;
    }
  }
);

const additionalDetailsMasterSlice = createSlice({
  name: "additionalDetailsMasterSlice",
  initialState: additionalDetailsMasterState,
  reducers: {
    setAdditionalDetailsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDetailsMasterSearchQuery: (state, action) => {
      state.filters.searchQuery = action.payload;
    },
    setDetailsMasterPagination: (state, action) => {
      state.filters.pagination = action.payload;
    },
    resetTemplateState: () => {
      return {
        ...additionalDetailsMasterState,
      };
    },
  },
  extraReducers: (builder) => {
    //Get all additional Details list
    builder.addCase(getAdditionalDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdditionalDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.additionalDetailsList = action.payload?.data?.records;
      state.additionalDetailsListData = action.payload.data;
    });
    builder.addCase(getAdditionalDetails.rejected, (state) => {
      state.loading = false;
      state.additionalDetailsList = [];
    });
    /* Reset entire state */
    builder.addCase(resetTemplateState, () => {
      return {
        ...additionalDetailsMasterState,
      };
    });
  },
});

export const {
  setDetailsMasterSearchQuery,
  setDetailsMasterPagination,
  resetTemplateState,
  setAdditionalDetailsLoading,
} = additionalDetailsMasterSlice.actions;

export default additionalDetailsMasterSlice.reducer;
