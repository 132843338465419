//Add constant data here
import routesConstants from "@/routes/routesConstants";
import {
  AdditionalNotesMaster,
  Admins,
  Companies,
  Customers,
  Dashboard,
  Delivery_Notes,
  Engineers,
  FAQs,
  Good_Receipts,
  Jobs,
  Permission_Profiles,
  Products,
  Projects,
  Services,
  Skills,
  Stock_Movements,
  Suppliers,
  Templates,
} from "./images";
import { GetAllPermissionMessages } from "@/components/common/commonFunctions";

export const roles = {
  superAdmin: "NisystSuperAdmin",
  companyAdmin: "CompanyAdmin",
  nisystAdmin: "NisystAdmin",
  companySuperAdmin: "CompanySuperAdmin",
};

// Role wise handling
export const menuIdMap = {
  Admins: 1,
  PermissionProfile: 2,
  Companies: 3,
  Engineers: 4,
  Products: 5,
  GoodReceipt: 6,
  StockMovements: 7,
  Services: 8,
  Skills: 9,
  Supplier: 10,
  SupplierContacts: 11,
  Customers: 12,
  CustomersContacts: 13,
  Shops: 14,
  ShopsContacts: 15,
  ShopsAdditionalDetails: 16,
  DeliveryNoteHeader: 17,
  DeliveryNoteItems: 18,
  Projects: 19,
  Jobs: 20,
  JobNotes: 21,
  JobDeliveryNotes: 22,
  JobTasks: 23,
  JobTaskProgress: 24,
  JobActivityLog: 25,
  CustomerFeedback: 26,
  Support: 27,
  TaskTemplate: 28,
  RescheduleJob: 29,
  AllocateChangeEngineer: 30,
  Dashboard: 31,
};
//Header
export const navLinks = [
  {
    name: "Dashboard",
    href: routesConstants?.HOME,
    iconUrl: Dashboard,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Dashboard,
  },
  {
    name: "Jobs",
    href: routesConstants?.JOBS,
    iconUrl: Jobs,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Jobs,
  },
  {
    name: "Delivery Notes",
    href: routesConstants?.DELIVERY_NOTES,
    iconUrl: Delivery_Notes,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: 0,
  },
  {
    name: "Products",
    href: routesConstants?.PRODUCTS,
    iconUrl: Products,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Products,
  },
  {
    name: "Suppliers",
    href: routesConstants?.SUPPLIER,
    iconUrl: Suppliers,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Supplier,
  },
  {
    name: "Goods Receipts",
    href: routesConstants?.GOODS_RECEIPT,
    iconUrl: Good_Receipts,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.GoodReceipt,
  },
  {
    name: "Stock Movement",
    href: routesConstants?.STOCK_MOVEMENT,
    iconUrl: Stock_Movements,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.StockMovements,
  },
  {
    name: "Customers",
    href: routesConstants?.CUSTOMERS,
    iconUrl: Customers,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Customers,
  },
  {
    name: "Services",
    href: routesConstants?.SERVICES,
    iconUrl: Services,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Services,
  },
  {
    name: "Skills",
    href: routesConstants?.SKILLS,
    iconUrl: Skills,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Skills,
  },
  {
    name: "Additional Details Master",
    href: routesConstants?.ADDITIONAL_DETAILS_MASTER,
    iconUrl: AdditionalNotesMaster,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: 0,
  },
  {
    name: "Task Templates",
    href: routesConstants?.TEMPLATES,
    iconUrl: Templates,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.TaskTemplate,
  },
  {
    name: "Projects",
    href: routesConstants?.PROJECTS,
    iconUrl: Projects,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Projects,
  },
  {
    name: "Companies",
    href: routesConstants?.COMPANIES,
    iconUrl: Companies,
    roles: [roles?.superAdmin, roles?.nisystAdmin],
    menuId: menuIdMap?.Companies,
  },
  {
    name: "Engineers",
    href: routesConstants?.ENGINEERS,
    iconUrl: Engineers,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Engineers,
  },
  {
    name: "Admins",
    href: routesConstants?.ADMINS,
    iconUrl: Admins,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: menuIdMap?.Admins,
  },
  {
    name: "Permission Profiles",
    href: routesConstants?.PERMISSIONS_PROFILES,
    iconUrl: Permission_Profiles,
    roles: [roles?.superAdmin, roles?.nisystAdmin],
    menuId: menuIdMap?.PermissionProfile,
  },
  {
    name: "FAQs",
    href: routesConstants?.FAQ,
    iconUrl: FAQs,
    roles: [
      roles?.superAdmin,
      roles?.companyAdmin,
      roles?.nisystAdmin,
      roles?.companySuperAdmin,
    ],
    menuId: 0,
  },
];

export const menuPermissionsMap = {
  [menuIdMap.Dashboard]: ["hide", "view"],
  [menuIdMap.Admins]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.PermissionProfile]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.Companies]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.Engineers]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.Products]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.GoodReceipt]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.StockMovements]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.Services]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.Skills]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.Supplier]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.SupplierContacts]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.Customers]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.CustomersContacts]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.Shops]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.ShopsContacts]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.ShopsAdditionalDetails]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.DeliveryNoteHeader]: ["hide", "view", "add", "edit"],
  [menuIdMap.DeliveryNoteItems]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.Projects]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.Jobs]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.JobNotes]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.JobDeliveryNotes]: ["hide", "view", "add", "edit", "delete"],
  [menuIdMap.JobTasks]: ["hide", "view"],
  [menuIdMap.JobTaskProgress]: ["hide", "view"],
  [menuIdMap.JobActivityLog]: ["hide", "view"],
  [menuIdMap.CustomerFeedback]: ["hide", "view"],
  [menuIdMap.Support]: ["hide", "view", "add", "delete"],
  [menuIdMap.TaskTemplate]: ["hide", "view", "add", "edit", "delete", "block"],
  [menuIdMap.RescheduleJob]: ["hide", "add"],
  [menuIdMap.AllocateChangeEngineer]: ["hide", "edit"],
};

// Conditionally modify permissions based on the permission type
export const updateMenuPermissionsForRole = (profile) => {
  const updatedMenuPermissions = { ...menuPermissionsMap };

  // For Company Admin, remove "add" permission from Jobs
  if (+profile === 3) {
    updatedMenuPermissions[menuIdMap.Jobs] = updatedMenuPermissions[
      menuIdMap.Jobs
    ].filter((perm) => perm !== "add");
  }

  // You can add similar conditions for other roles if needed
  return updatedMenuPermissions;
};

export const commonPermissionMessages = {
  hide: (menuName) => `Hide ${menuName} screen`,
  view: (menuName) =>
    menuName === "job task" ||
    menuName === "job task progress" ||
    menuName === "delivery note item"
      ? `Allow to view ${menuName}`
      : `Allow to view ${menuName} details`,
  add: (menuName) =>
    menuName === "support"
      ? "Allow to send message"
      : `Allow to add new ${menuName}`,
  edit: (menuName) => `Allow to update ${menuName}`,
  delete: (menuName) =>
    menuName === "support"
      ? "Allow to close support"
      : `Allow to delete ${menuName}`,
  block: (menuName) => `Allow to active/deactivate ${menuName}`,
};

export const permissionMessagesMap = {
  [menuIdMap.Dashboard]: {
    hide: commonPermissionMessages.hide("dashboard"),
    view: commonPermissionMessages.view("dashboard"),
  },
  [menuIdMap.Admins]: GetAllPermissionMessages("admins", "admin"),
  [menuIdMap.PermissionProfile]: GetAllPermissionMessages(
    "permission profile",
    "permission profile"
  ),
  [menuIdMap.Companies]: GetAllPermissionMessages("companies", "company"),
  [menuIdMap.Engineers]: GetAllPermissionMessages("engineers", "engineer"),
  [menuIdMap.Products]: GetAllPermissionMessages("products", "product"),
  [menuIdMap.GoodReceipt]: GetAllPermissionMessages(
    "goods receipt",
    "goods receipt"
  ),
  [menuIdMap.StockMovements]: GetAllPermissionMessages(
    "stock movements",
    "stock movement"
  ),
  [menuIdMap.Services]: GetAllPermissionMessages("services", "service"),
  [menuIdMap.Skills]: GetAllPermissionMessages("skills", "skill"),
  [menuIdMap.Supplier]: GetAllPermissionMessages("suppliers", "supplier"),
  [menuIdMap.SupplierContacts]: GetAllPermissionMessages(
    "supplier contacts",
    "supplier contact"
  ),
  [menuIdMap.Customers]: GetAllPermissionMessages("customers", "customer"),
  [menuIdMap.CustomersContacts]: GetAllPermissionMessages(
    "customer contacts",
    "customer contact"
  ),
  [menuIdMap.Shops]: GetAllPermissionMessages("shops", "shop"),
  [menuIdMap.ShopsContacts]: GetAllPermissionMessages(
    "shop contacts",
    "shop contact"
  ),
  [menuIdMap.ShopsAdditionalDetails]: {
    hide: commonPermissionMessages.hide("shop additional details"),
    view: commonPermissionMessages.view("shop additional"),
    add: commonPermissionMessages.add("shop additional detail"),
    edit: commonPermissionMessages.edit("shop additional detail"),
    delete: commonPermissionMessages.delete("shop additional detail"),
  },
  [menuIdMap.DeliveryNoteHeader]: GetAllPermissionMessages(
    "delivery note header",
    "delivery note header"
  ),
  [menuIdMap.DeliveryNoteItems]: GetAllPermissionMessages(
    "delivery note items",
    "delivery note item"
  ),
  [menuIdMap.Projects]: GetAllPermissionMessages("projects", "project"),
  [menuIdMap.Jobs]: GetAllPermissionMessages("jobs", "job"),
  [menuIdMap.JobNotes]: GetAllPermissionMessages("job notes", "job note"),
  [menuIdMap.JobDeliveryNotes]: GetAllPermissionMessages(
    "job delivery notes",
    "job delivery note"
  ),
  [menuIdMap.JobTasks]: {
    hide: commonPermissionMessages.hide("job tasks"),
    view: commonPermissionMessages.view("job task"),
  },
  [menuIdMap.JobTaskProgress]: {
    hide: commonPermissionMessages.hide("job task progress"),
    view: commonPermissionMessages.view("job task progress"),
  },
  [menuIdMap.JobActivityLog]: {
    hide: commonPermissionMessages.hide("job activity log"),
    view: commonPermissionMessages.view("job activity log"),
  },
  [menuIdMap.CustomerFeedback]: {
    hide: commonPermissionMessages.hide("customer feedback"),
    view: commonPermissionMessages.view("customer feedback"),
  },
  [menuIdMap.Support]: {
    hide: commonPermissionMessages.hide("support"),
    view: commonPermissionMessages.view("support"),
    add: commonPermissionMessages.add("support"),
    delete: commonPermissionMessages.delete("support"),
  },
  [menuIdMap.TaskTemplate]: GetAllPermissionMessages(
    "task templates",
    "task template"
  ),
  [menuIdMap.RescheduleJob]: {
    hide: "Hide job reschedule feature",
    add: "Allow to reschedule job",
  },
  [menuIdMap.AllocateChangeEngineer]: {
    hide: "Hide change engineer feature",
    edit: "Allow to change engineer",
  },
};

export const profileMenuItems = [
  {
    id: 1,
    text: "My Profile",
    link: routesConstants?.PROFILE,
  },
  {
    id: 2,
    text: "Change Password",
    link: routesConstants?.CHANGE_PASSWORD,
  },
  {
    id: 3,
    text: "Logout",
    link: "#",
  },
];
//Footer
export const footerLinks = ["About Us", "Contact Us", "FAQs"];

export const skillsStatusConst = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 2,
  },
];

export const serviceStatusConst = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 2,
  },
];

export const engineerStatusConst = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Active",
    value: 2,
  },
  {
    label: "Inactive",
    value: 1,
  },
];

export const emojiRegex =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

// export const MobilePhoneRegex = /^\d{9,14}$/;
export const MobilePhoneRegex = /^[+\d][\d\s+-]{8,13}$/;
export const EmailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const TelephoneRegex = /^(\+\d{1,2}-\d{3}-\d{3}-\d{4}|\d{10})$/;
export const ukMobileNumberRegex = /^\+44\s?7\d{3}\s?\d{6}$/;
export const PasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/;
export const WebsiteRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

//Templates
export const taskOptions = [
  "Single Checkbox",
  "Single Checkbox with text",
  "Single Checkbox with Number",
  "Multiple Checkbox",
  "Radio Button (Any One)",
  "Dropdown",
  "Images",
  "Date Picker",
];
export const statusConst = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

export const FieldType = {
  SingleCheckbox: 1,
  SingleTickBoxWithText: 2,
  SingleTickBoxWithNumber: 3,
  MultipleCheckbox: 4,
  "Radio Button (Any One)": 5,
  Dropdown: 6,
  Images: 7,
  "Date Picker": 8,
};

export const fieldTypeTitles = {
  [FieldType.SingleCheckbox]: "Single Checkbox",
  [FieldType.SingleTickBoxWithText]: "Single Checkbox with text",
  [FieldType.SingleTickBoxWithNumber]: "Single Checkbox with Number",
  [FieldType.MultipleCheckbox]: "Multiple Checkbox",
  [FieldType["Radio Button (Any One)"]]: "Radio Button (Any One)",
  [FieldType.Dropdown]: "Dropdown",
  [FieldType.Images]: "Images",
  [FieldType["Date Picker"]]: "Date Picker",
};

export const PermissionProfileType = {
  SuperAdmin: 1,
  Nisyst: 2,
  Company: 3,
};

export const DaysOfWeek = {
  Sunday: 7,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

// for shop timing
export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const jobsTabs = [
  {
    id: 1,
    label: "Job Task Progress",
  },
  {
    id: 2,
    label: "Activity Logs",
  },
  {
    id: 3,
    label: "Delivery Notes",
  },
  {
    id: 4,
    label: "Task Details",
  },
  {
    id: 5,
    label: "Customer Feedback",
  },
  {
    id: 6,
    label: "Customer & Shop Details",
  },
  {
    id: 7,
    label: "Notes",
  },
  {
    id: 8,
    label: "Support",
  },
];

//Jobs
export const selectTimePeriod = [
  {
    label: "AM",
    value: 1,
  },
  {
    label: "PM",
    value: 2,
  },
];
export const jobWizardSteps = [
  "1. Basic Details",
  "2. Schedule Details",
  "3. Task Template",
];

export const jobFilters = [
  { value: null, label: "All" },
  { value: 0, label: "Outstanding" },
  { value: 1, label: "In Progress" },
  { value: 2, label: "In-Complete" },
  { value: 3, label: "Completed" },
  { value: 4, label: "Cancelled" },
  { value: 5, label: "Draft" },
];

export const deliveryFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "Live" },
  { value: 2, label: "Completed" },
  { value: 3, label: "Allocated to Job" },
];

export const counterData = [
  {
    title: "Live Projects",
    description: {
      all: "Total live projects",
    },
    all: 576,
  },
  {
    title: "Live Jobs",
    description: {
      all: "Total live jobs",
    },
    all: 1056,
  },
  {
    title: "WIP Jobs",
    description: {
      all: "Total wip jobs",
    },
    all: 110,
  },
  {
    title: "Upcoming Jobs",
    description: {
      all: "Total upcoming jobs",
    },
    all: 63,
  },
  {
    title: "Completed Jobs",
    description: {
      all: "Total completed jobs",
    },
    all: 1367,
  },
];

export const scaleTicksConfig = {
  // maxRotation: 45,
  // minRotation: 45,
  callback: function (value) {
    const text = this.getLabelForValue(value);
    if (text?.length > 15) {
      return text?.slice(0, 5) + "..." + text?.slice(text?.length - 5);
    } else {
      return text;
    }
  },
};

export const zoomConfig = (mode = "xy") => ({
  pan: {
    enabled: true,
    mode,
  },
  zoom: {
    pinch: {
      enabled: true, // Enable pinch zooming
    },
    wheel: {
      enabled: true, // Enable wheel zooming
    },
    mode,
  },
});

export const adminHorizontalBarChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  interaction: {
    mode: "index",
    intersect: true,
  },
  plugins: {
    zoom: zoomConfig("y"),
    legend: {
      padding: 10,
      position: "bottom",
      labels: {
        color: "#263238",
        font: {
          size: 15,
          weight: 400,
        },
        boxWidth: 36,
        boxHeight: 18,
      },
    },
    datalabels: {
      color: "#ffffff", // Label text color
      anchor: "end", // Anchor position
      align: "end", // Alignment
      font: {
        size: 14,
        weight: "bold",
        family: "'Arial', sans-serif",
      },
      formatter: (value) => `${value}`, // Custom label format
    },
  },
  scales: {
    y: {
      ticks: { ...scaleTicksConfig, maxRotation: 45, minRotation: 45 },
      title: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    x: {
      // suggestedMin: 0,
      // suggestedMax: 10,
      ticks: scaleTicksConfig,
      title: {
        display: false,
      },
    },
  },
};
export const jobBarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
  },
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 10,
      beginAtZero: true,
      ticks: scaleTicksConfig,
      title: {
        text: "Jobs",
        display: true,
        font: {
          weight: 700,
          size: 14,
        },
        color: "black",
      },
    },
  },
};
export const jobBarChartData = {
  week: {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Jobs Completed (Week)",
        data: [120, 150, 170, 140, 190, 220, 200],
        borderColor: "#01afd7",
        backgroundColor: "#01afd7",
      },
    ],
  },
  month: {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Jobs Completed (Month)",
        data: [450, 600, 550, 700],
        borderColor: "#01afd7",
        backgroundColor: "#01afd7",
      },
    ],
  },
  year: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Jobs Completed (Year)",
        data: [
          3200, 3300, 3400, 3100, 3500, 2800, 2900, 2000, 3900, 3400, 1500, 0,
        ],
        borderColor: "#01afd7",
        backgroundColor: "#01afd7",
      },
    ],
  },
};

export const emptyChartData = {
  week: {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Jobs Completed (Week)",
        data: [], // Empty data array
        borderColor: "#d3d3d3", // Optional: Light gray to indicate no data
        backgroundColor: "#d3d3d3",
      },
    ],
  },
  month: {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Jobs Completed (Month)",
        data: [], // Empty data array
        borderColor: "#d3d3d3",
        backgroundColor: "#d3d3d3",
      },
    ],
  },
  year: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Jobs Completed (Year)",
        data: [], // Empty data array
        borderColor: "#d3d3d3",
        backgroundColor: "#d3d3d3",
      },
    ],
  },
};

export const jobDoughnutChartOptions = {
  cutout: "60%",
  responsive: true,
  layout: {
    padding: 2,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const jobHorizontalBarChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  interaction: {
    mode: "index",
    intersect: true,
  },
  plugins: {
    zoom: zoomConfig("y"),
    legend: {
      padding: 10,
      position: "bottom",
      labels: {
        color: "#263238",
        font: {
          size: 15,
          weight: 400,
        },
        boxWidth: 36,
        boxHeight: 18,
      },
    },
  },
  scales: {
    y: {
      ticks: { ...scaleTicksConfig, maxRotation: 45, minRotation: 45 },
      title: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    x: {
      // suggestedMin: 0,
      // suggestedMax: 10,
      ticks: scaleTicksConfig,
      title: {
        display: false,
      },
    },
  },
};

export const dateWiseFilter = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Today",
    value: 1,
  },
  {
    label: "Yesterday",
    value: 2,
  },
  {
    label: "Week",
    value: 3,
  },
  {
    label: "Month",
    value: 4,
  },
  {
    label: "Year",
    value: 5,
  },
];

export const dashboardColor = [
  "#f6f68973",
  "#ffc0cb87",
  "#00800024",
  "#87ceeb5c",
  "#0000ff24",
];
