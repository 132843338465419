import React from "react"
import { getBrowserName, getOSName } from "./getDeviceInfo"
import { encryptString, generateRandomString } from "@/utils"
import { axiosNisystAdmin } from "@/services/api"
import { ADD_FRONT_END_ERROR_LOG_URL } from "@/services/url"

const sendErrorLog = async ({ message, description, ...extra }) => {
    try {
        const SecurityToken = generateRandomString()
        await axiosNisystAdmin.post(
            ADD_FRONT_END_ERROR_LOG_URL,
            {
                ...extra,
                message,
                description,
                request: "",
                plateFromType: 1,
                securityToken: encryptString(SecurityToken),
            },
            {
                headers: {
                    type: 1,
                    version: React.version,
                    model: getBrowserName(),
                    os: getOSName(),
                },
            }
        )
    } catch (e) {
        console.log(e)
    }
}

export default sendErrorLog
